<div class="page-title-area">
    <img src="assets/images/platformbkg.jpeg" style="height: 700px; width: 100%; position: relative; z-index: -1;">
    <div class="container" style="position: relative; margin-top: -35%;">
        <div class="page-title-content">
            <h2 style="font-size: 50px;">Resources</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Resources</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>



<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/casestudy.jpeg" alt="company" />
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-left: 10%;">
                    <div class="company-tittle">
                        <span>  SNIP-X</span>
                        <h2>Case Studies</h2>
                        <p>AR/VR/MR metaverse case studies provide real-world examples and insights into how these technologies have been successfully applied in various contexts. They showcase specific projects, their objectives, implementation strategies, and the resulting impact. Case studies offer practical information, lessons learned, and best practices, serving as valuable references for organizations considering similar AR/VR/MR initiatives.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-right: 10%;">
                    <div class="company-tittle">
                        <span>  SNIP-X</span>
                        <h2>Value Addition Reports</h2>
                        <p>Value addition reports focus on the benefits and added value that AR/VR/MR technologies bring to different industries and sectors. These reports analyze the impact of these technologies on productivity, efficiency, cost savings, customer experiences, training effectiveness, and other key metrics. They provide data-driven evidence to support investment decisions, policy-making, and strategic planning.
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/var.jpeg" alt="company" />
                </div>
            </div>
        </div>
    </div>
</section>
<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/wp.jpeg" alt="company" />
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-left: 10%;">
                    <div class="company-tittle">
                        <span>  SNIP-X</span>
                        <h2>White Papers</h2>
                        <p>White papers delve deeper into specific topics related to AR/VR/MR metaverse technologies. They explore industry trends, emerging technologies, technical considerations, design principles, ethical implications, and other relevant subjects. White papers are often authored by experts and thought leaders, offering insights and expert opinions that help shape the direction of the industry.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-right: 10%;">
                    <div class="company-tittle">
                        <span>  SNIP-X</span>
                        <h2>Blogs</h2>
                        <p>Blogs provide a platform for industry professionals, researchers, and enthusiasts to share their thoughts, experiences, and opinions on AR/VR/MR metaverse technologies. They cover a wide range of topics, including use cases, design considerations, industry news, emerging trends, and practical tips. Blogs foster a sense of community, encourage discussions, and provide a valuable source of information and inspiration for individuals seeking to stay up-to-date with the latest developments in the field.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/ba.jpeg" alt="company" />
                </div>
            </div>
        </div>
    </div>
</section>

