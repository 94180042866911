
<div class="page-title-area">
    <img src="assets/images/abouttitlebkg.jpeg" style="height: 700px; width: 100%; position: relative; z-index: -1;">
    <div class="container" style="position: relative; margin-top: -35%;">
        <div class="page-title-content">
            <h2 style="font-size: 50px;">Company</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Company</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<div style=" margin-top: 7%;">
    <h1 style="position: relative; left: 41.5%;">ABOUT SNIP-X</h1>
    <p style="padding-top: 5%; margin-left: 7%; margin-right: 7%; font-size: 20px;">SNIP-X is a revolutionary platform that uses Augmented Reality (AR), Virtual Reality (VR), and Mixed Reality (MR) to transform various industries and businesses. SNIP-X helps you to cut down human inefficiencies and deliver operational excellence in fields such as medical education, oil and gas, defence, and aviation.

        SNIP-X is powered by Emerging Technologies Portfolio & Platforms (ETPP), a division of Atyaf eSolutions, a leading IT company based in Kuwait. ETPP provides innovative solutions using cutting-edge technologies such as AR, VR, MR, AI, IoT, and blockchain. ETPP has a team of experts and professionals who are passionate about creating value for their clients and partners.
        
        SNIP-X is also inspired by Mathpix, a document cloud for research that uses AI to convert images and PDFs to useful formats such as LaTeX, Markdown, HTML, and more. Mathpix is an ecosystem of products that power careers in STEM and make scientific communication easy and rewarding. Mathpix has a vision to democratize access to scientific knowledge and tools for everyone.
        
        SNIP-X is not just a product, but a vision to create a better future for humanity. SNIP-X aims to empower people with immersive and interactive experiences that enhance their learning, performance, and collaboration. SNIP-X is the ultimate tool for digital transformation in the 21st century. SNIP-X enables you to create, share, and explore content in AR, VR, and MR with ease and efficiency.
        
        Join us today and discover how SNIP-X can change your world.</p>
</div>

<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content">
                    <div class="company-tittle">
                        <span>Our Company</span>
                        <h2>What we actually do?</h2>
                        <p>At SnipX, we specialize in harnessing the power of Augmented Reality (AR), Virtual Reality (VR), and Mixed Reality (MR) technologies to revolutionize industries and elevate human experiences. Our dedicated team of experts combines their deep knowledge of emerging technologies with a passion for innovation to create cutting-edge solutions that drive growth and efficiency across various sectors. We collaborate with businesses in diverse fields, including medical education, oil and gas, defense, and aviation, to reimagine their workflows and processes. By integrating AR, VR, and MR into their operations, we help them streamline complex tasks. We believe in the power of immersive experiences to accelerate learning and empower individuals. Through our interactive AR, VR, and MR applications, we provide immersive training modules and simulations that allow users to practice real-life scenarios in a safe and controlled environment.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/fb cover 2.png" alt="company" />
                </div>
            </div>
        </div>
    </div>
</section>

<section class="technology-area ptb-100 bg-color">
    <div class="container">
        <div class="row align-items-center choose-c">
            <div class="col-lg-6">
                <div>
                    <img src="assets/images/company/company-img.jpg"  style="height: 550px; width: 85%; border-radius: 8%;" alt="choose" />

                    <!-- <div class="technology-video">
                        <button type="button" class="video-btn p-0 border-0" (click)="openPopup()">
                            <i class='bx bx-play' ></i>
                        </button>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-6">
                <div class="technology-content">
                    <h2>We are more then you imagine</h2>
                    <p>Atyaf eSolutions (founded in 1995) was the pioneer and now' a leading solutions
                        provider in the IT space for the Kingdom of Bahrain as their HQ, with operations
                        and clientele spread across the entire MENA region. We are a bunch of tech gigs
                        along with few industry domain experts with 25+ years of rich experience
                        working in key industries like yours. Below is a glimpse of our solution stack and
                        capabilities for your reference.
                        Traditional IT — Web & Mobile Apps. Dev., Microsoft Azure & AWS Cloud
                        Practice, Cyber Security & Managed Services.
                        Emerging Tech — Blockchain-based solution for BFSI, Low Code Automation,
                        Chatbots, GRC and *siness & Analytics Solutions dedicated for Banks &
                        Financial Institutions.</p>
                    <a routerLink="/contact" class="box-btn">Contact Us</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- 
<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content">
                    <div class="company-tittle">
                        <span>Our Company</span>
                        <h2>SNIP-X Products</h2>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo eveniet animi sunt vel obcaecati velit incidunt vero, dicta omnis reiciendis, ipsum repellat aut rem id eum nobis accusamus praesentium aspernatur. Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil, tempore numquam excepturi tempora, ex maiores consequatur voluptas blanditiis quod enim itaque ut minus ad sit repellat atque eaque dolorem quas. Lorem ipsum dolor sit amet consectetur adipisicing elit. Error eveniet explicabo molestias illum natus ea doloribus dolorum tempora quasi, cupiditate sunt reiciendis omnis. Officiis error ratione facilis debitis suscipit saepe! Lorem ipsum dolor, sit amet consectetur adipisicing elit. Officia, dolorem sunt! Porro reprehenderit tenetur exercitationem impedit vel incidunt? Excepturi illo fugit laborum, vero quis commodi eius at beatae nulla quisquam?</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/fb cover 1.png" alt="company" />
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- Video Popup -->


<section class="feature-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="contnet">
                    <div class="feature-tittle">
                        <span>Features</span>
                        <h2>Some of our features</h2>
                        <p>At SnipX, our mission is to empower businesses and individuals by leveraging the transformative potential of AR, VR, and MR.</p>
                    </div>
                    <ul>
                        <li><i class="flaticon-correct"></i>Research and Innovation</li>
                        <li><i class="flaticon-correct"></i>Client Collaboration and Support</li>
                        <li><i class="flaticon-correct"></i>Hardware and Software Integration</li>
                        <li><i class="flaticon-correct"></i>Design and User Experience</li>
                        <li><i class="flaticon-correct"></i>Cross-Platform Compatibility</li>
                    </ul>
                    <a routerLink="/contact" class="box-btn">Lets Talk!</a>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="feature-img">
                    <img src="assets/images/feature-img.png" alt="feature" />
                </div>
            </div>
        </div>
    </div>
</section>
