<div class="page-title-area">
    <img src="assets/images/platformbkg.jpeg" style="height: 700px; width: 100%; position: relative; z-index: -1;">
    <div class="container" style="position: relative; margin-top: -35%;">
        <div class="page-title-content">
            <h2 style="font-size: 50px;">Platform Flow</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Platform Flow</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="technology-area ptb-100 bg-color">
    <div class="container">
        <div class="row align-items-center choose-c">
            <div class="col-lg-6">
                <div>
                    <img src="assets/images/flo1.jpeg"  style="border-radius: 8%;" alt="choose" />

                    <!-- <div class="technology-video">
                        <button type="button" class="video-btn p-0 border-0" (click)="openPopup()">
                            <i class='bx bx-play' ></i>
                        </button>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-6">
                <div class="technology-content">
                    <h2>Overview</h2>
                    <p>SNIP-X has introduced a groundbreaking developer tool tailored for XR Content Pros, facilitating the creation of high-quality XR (Virtual Reality & Mixed Reality) scenarios in a standardized manner. This innovative platform empowers content creators to design immersive and engaging experiences seamlessly. By utilizing SNIP-X, XR developers can efficiently author virtual reality and mixed reality content, ensuring consistency and compatibility across various platforms and devices. The tool's user-friendly interface allows for effortless integration of interactive elements, 3D assets, and realistic environments, all while adhering to industry standards. With SNIP-X, XR Content Pros can unlock their creative potential and deliver cutting-edge XR experiences that captivate audiences and redefine the future of digital content.</p>
                    <a routerLink="/contact" class="box-btn">Contact Us</a>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="feature-area bg-color ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="contnet">
                    <div class="feature-tittle">
                        <span>Solutions</span>
                        <h2>SNIP-X Platform</h2>
                        <p>We offer a comprehensive range of nodes that encompass various functionalities, including grabbing, teleporting, gazing, and more. Additionally, our platform supports the integration of custom nodes, allowing for the incorporation of specialized features to meet unique and specific requirements.</p>
                    </div>
                    <ul>
                        <li><i class="flaticon-correct"></i> Multiuser
                        </li>
                        <li><i class="flaticon-correct"></i> PORTAL integration
                        </li>
                        <li><i class="flaticon-correct"></i> Scenario Randomization
                        </li>
                        <li><i class="flaticon-correct"></i> Automatic UI positioning
                        </li>
                        <li><i class="flaticon-correct"></i> Skipping/jumping between steps and many more
                        </li>
                    </ul>
                    <a routerLink="/contact" class="box-btn">Lets Talk!</a>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="feature-img">
                    <img src="assets/images/feature-img.png" alt="feature" />
                </div>
            </div>
        </div>
    </div>
</section>
<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/autoui.jpeg" alt="company" />
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-left: 10%;">
                    <div class="company-tittle">
                        <span>  SNIP-X</span>
                        <h2>Key Benifits</h2>
                        <p>1. No prior experience needed <br>
                            2. Standardized workflow <br>
                            3. Reduced production time <br>
                            4. Easy to edit/tweak <br>
                            5. Key features supported with little set-up <br>
                            6. A visual representation of the scenario functionality <br>
                            7. Multiplatform <br>
                            8. VR object set up tool <br>
                            9. Session replay and review <br></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/multiuser.jpeg" alt="company" />
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-left: 10%;">
                    <div class="company-tittle">
                        <span>  SNIP-X</span>
                        <h2>Multiuser</h2>
                        <p>SNIP-X incorporates a multiuser functionality, allowing multiple users to interact and collaborate within virtual worlds simultaneously. Users can invite friends or connect with other individuals within the SNIP-X community. They can engage in real-time communication, coordinate activities, and explore virtual environments together. This feature fosters social interaction and creates a dynamic and immersive experience for users to share and enjoy with others.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-right: 10%;">
                    <div class="company-tittle">
                        <span>  SNIP-X</span>
                        <h2>PORTAL Integration</h2>
                        <p>SNIP-X offers integration capabilities with other platforms and services, including the integration of PORTAL. This integration allows users to seamlessly access and interact with virtual worlds and experiences from within the PORTAL platform. Users can launch the SNIP-X application or content directly through PORTAL, simplifying the process of transitioning between different platforms and enhancing the overall user experience.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/tms.jpeg" alt="company" />
                </div>
            </div>
        </div>
    </div>
</section>
<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/autoui.jpeg" alt="company" />
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-left: 10%;">
                    <div class="company-tittle">
                        <span>  SNIP-X</span>
                        <h2>Automatic UI Positioning</h2>
                        <p>SNIP-X employs automatic UI positioning to optimize the user interface (UI) within the virtual environment. The platform dynamically adjusts the placement and visibility of UI elements based on the user's viewpoint and interactions. This ensures that relevant information, menus, and controls are conveniently positioned within the user's field of view, enhancing usability and reducing the need for manual adjustments.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-right: 10%;">
                    <div class="company-tittle">
                        <span>  SNIP-X</span>
                        <h2>Skipping/Jumping Between Steps</h2>
                        <p>To provide flexibility and efficiency, SNIP-X allows users to skip or jump between different steps or stages within the platform flow. For example, if a user is creating a virtual world and wants to modify a specific element or object, they can easily jump to the relevant editing section without going through all the previous steps again. This feature saves time and streamlines the user experience, enabling users to navigate and make changes more efficiently within the platform.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/ba.jpeg" alt="company" />
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/flo2.jpeg" alt="company" />
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-left: 10%;">
                    <div class="company-tittle">
                        <span>  SNIP-X</span>
                        <h2>Accelerate your deployments with FLOW</h2>
                        <p>Streamline your deployment process using FLOW! We empower enterprise clients, developers, and partners with efficient tools to create bug-free, process-driven training content that seamlessly integrates with Luminous templates and the PORTAL metaverse training platform. Leveraging our experience and content library, industrial-based training projects can rapidly scale up and accelerate deployment. Say goodbye to time-consuming setups and hello to accelerated success with FLOW.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="home-contact-area home-2-contact pb-100">
    <div class="container">
        <div class="section-title">
            <span>Contact Us</span>
            <h2>Let Us Know About Your Project Idea!</h2>
            <p>It is a long established fact that a reader will be distracted by the rea dable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more.</p>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="content">
                    <form id="contactForm">
                        <div class="row">
                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="name" id="name" class="form-control" required placeholder="Your Name" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="email" name="email" id="email" class="form-control" required placeholder="Your Email" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Your Phone" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Subject" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="message" class="form-control" id="message" cols="30" rows="5" required placeholder="Your Message"></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn page-btn box-btn">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="contact-img contact-img-2">
                    <img src="assets/images/contact-img2.jpg" alt="contact" />
                </div>
            </div>
        </div>
    </div>
</section>