<div class="page-title-area">
    <img src="assets/images/platformbkg.jpeg" style="height: 700px; width: 100%; position: relative; z-index: -1;">
    <div class="container" style="position: relative; margin-top: -35%;">
        <div class="page-title-content">
            <h2 style="font-size: 50px;">SNIP-X Platform</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Platform</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="technology-area ptb-100 bg-color">
    <div class="container">
        <div class="row align-items-center choose-c">
            <div class="col-lg-6">
                <div>
                    <img src="assets/images/platover.jpeg"  style="height: 550px; width: 85%; border-radius: 8%;" alt="choose" />

                    <!-- <div class="technology-video">
                        <button type="button" class="video-btn p-0 border-0" (click)="openPopup()">
                            <i class='bx bx-play' ></i>
                        </button>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-6">
                <div class="technology-content">
                    <h2>Overview</h2>
                    <p>•SNIP-X is one of its first kinds of AR|VR|MR platform completely build on SaaS model. The theme of SNIP-X is, our platform helps different industries & businesses to cut down “Human Inefficiencies, Errors, Cost associated with Training”.

                        <br>•SNIP-X also act as a tool for one of the fastest ways of knowledge transfer and digitally transform our customer’s eco-system to achieve an edge over their competition.
                        
                        <br>•The idea is inspired from the top Metaverse influencers and industry leaders re-defining the concept of Human 2.0 which explains “bridging the gap between man & machine” through “Extended Reality (XR), Automation and Industry 4.0 initiatives”.
                                            

                        </p>
                    <a routerLink="/contact" class="box-btn">Contact Us</a>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="col-lg-12">
    <div class="company-img">
        <img src="assets/images/abtx.png">
    </div>
</div>
<section class="feature-area bg-color ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="contnet">
                    <div class="feature-tittle">
                        <span>Solutions</span>
                        <h2>SNIP-X Platform</h2>
                        <p>SNIP-X offers cutting-edge solutions in augmented reality, virtual reality, mixed reality, metaverse, and virtual showrooms, transforming industries with immersive experiences and innovative technology. Harness the power of SNIP-X to unlock new possibilities and enhance your business.</p>
                    </div>
                    <ul>
                        <li><i class="flaticon-correct"></i> Single & Multi-User Sessions
                        </li>
                        <li><i class="flaticon-correct"></i> Tailored-made Trainings
                        </li>
                        <li><i class="flaticon-correct"></i> Scenario Randomization
                        </li>
                        <li><i class="flaticon-correct"></i> Real-Time Evaluation Feedback
                        </li>
                        <li><i class="flaticon-correct"></i> Remote Real-Time User Viewing
                        </li>
                        <li><i class="flaticon-correct"></i> On-Job Assistance
                        </li>
                        <li><i class="flaticon-correct"></i> Remote Collaboration Functionality
                        </li>
                        <li><i class="flaticon-correct"></i> Single Sign on Integration
                        </li>
                        <li><i class="flaticon-correct"></i> Session Recording
                        </li>
                    </ul>
                    <a routerLink="/contact" class="box-btn">Lets Talk!</a>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="feature-img">
                    <img src="assets/images/feature-img.png" alt="feature" />
                </div>
            </div>
        </div>
    </div>
</section>
<div class="container" style="margin-top: 10%;">
        

    <div class="row">
        <div class="col-lg-4 col-sm-6">
            
                <div class="service-img">
                    <img src="assets/images/abt1.png" alt="service" />
                </div>

                
            
        </div>

        <div class="col-lg-4 col-sm-6">
            
            <div class="service-img">
                <img src="assets/images/abt5.png" alt="service" />
            </div>

            
        
    </div>

    <div class="col-lg-4 col-sm-6">
            
        <div class="service-img">
            <img src="assets/images/abt4.png" alt="service" />
        </div>

        
    
</div>

<div class="col-lg-6 col-sm-6">
            
    <div class="service-img">
        <img src="assets/images/abt3.png" alt="service" />
    </div>

    

</div>

<div class="col-lg-6 col-sm-6">
            
    <div class="service-img">
        <img src="assets/images/abt2.png" alt="service" />
    </div>

    

</div>

        
    </div>
</div>
<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-right: 10%;">
                    <div class="company-tittle">
                        <span>SNIP-X</span>
                        <h2>Platform

                        </h2>
                        <h3>Cross-Platform                        </h3>
                        <p>
                            Virtual Reality (HTC Vive, Meta Quest 2) <br>
Augmented Reality (Smart Glasses) <br>
Mixed Reality (Microsoft HoloLens) <br>
WebApp <br>
Mobile App (iOS & Android) <br>
</p>
                        <h3>Trainee Dashboard                        </h3>
                        <p>Training Mode (AR/VR/MR) <br>
                            Evaluation Mode (AR/VR/MR) <br>
                            LMS & Leaderboard <br>
                            </p>
                            <h3>Trainer Dashboard                            </h3>
                            <p>User Analytics & Insights <br>
                                Manage Training Courses <br>
                                Schedule & Assign Training <br>
                                </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/plat1.jpeg" alt="company" style="width: 100%;" />
                </div>
            </div>
        </div>
    </div>
</section>
<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/pflow.jpeg" alt="company" />
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-left: 10%;">
                    <div class="company-tittle">
                        <span>  SNIP-X</span>
                        <h2> PLatform flow</h2>
                        <p>The SNIP-X Platform follows a seamless flow from content creation to deployment. The platform provides an intuitive interface for content creators to develop immersive training modules, interactive guides, and real-time assistance features. Once the content is created, it can be easily deployed to the target devices, ensuring accessibility and user-friendly experiences.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-right: 10%;">
                    <div class="company-tittle">
                        <span>  SNIP-X</span>
                        <h2>TMS Portal</h2>
                        <p>The SNIP-X Platform includes a comprehensive Training Management System (TMS) Portal. This portal serves as a central hub for managing training content, tracking user progress, and monitoring performance. Administrators can easily assign and schedule training modules, track trainee progress, and generate detailed reports to measure training effectiveness.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/tms.jpeg" alt="company" />
                </div>
            </div>
        </div>
    </div>
</section>
<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/ojt.jpeg" alt="company" />
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-left: 10%;">
                    <div class="company-tittle">
                        <span>  SNIP-X</span>
                        <h2>On-job Training</h2>
                        <p>SNIP-X Train also supports On-job Training, enabling trainees to apply their skills and knowledge in real-world scenarios. Trainees can use AR/VR/MR devices to receive real-time instructions and guidance, helping them perform tasks accurately and efficiently.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-right: 10%;">
                    <div class="company-tittle">
                        <span>  SNIP-X</span>
                        <h2>Breakdown Assist</h2>
                        <p>Breakdown Assist is designed to provide immediate support during equipment breakdowns or critical situations. It enables users to access troubleshooting guides, maintenance manuals, and visual instructions in real-time. By overlaying relevant information onto the physical equipment, technicians can quickly identify issues and perform necessary repairs, minimizing downtime.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/ba.jpeg" alt="company" />
                </div>
            </div>
        </div>
    </div>
</section>

