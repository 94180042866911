<header class="header-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-2 col-sm-0">
                <div class="logo">
                    <a routerLink="/"><img src="assets/images/logo-rgb.png" alt="logo"
                            style="border-radius: 10px;" /></a>
                </div>
            </div>

            <div class="col-lg-8 col-sm-6 text-end pr-0">
                <div class="header-content-right">
                    <ul class="header-contact">
                        <li><a href="tel:+97317877900"><i class="bx bxs-phone-call"></i>+97317877900</a></li>
                        <li><a href="mailto:snipx@atyafesolutions.com"><i
                                    class="bx bxs-envelope"></i>snipx@atyafesolutions.com</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-sm-6 text-end pl-0">
                <div class="header-content-right">
                    <ul class="header-social">
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="#" target="_blank"> <i class="bx bxs-envelope"></i></a></li>
                        <li><a href="#" target="_blank"> <i class="bx bxl-youtube"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</header>

<div class="{{navbarClass}}">
    <div class="main-nav" [ngClass]="{'sticky': isSticky}">
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light" [class.active]="classApplied">
                <a class="navbar-brand d-none" routerLink="/">
                    <img src="assets/images/logo-rgb.jpg" alt="logo" style="border-radius: 10px;" />
                </a>
                <button class="navbar-toggler" type="button" (click)="toggleClass()">
                    <span class="burger-menu">
                        <span class="top-bar"></span>
                        <span class="middle-bar"></span>
                        <span class="bottom-bar"></span>
                    </span>
                </button>
                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav text-left">
                        <li class="nav-item">
                            <a routerLink="/" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Home</a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Solutions</a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/ar-solutions" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">AR
                                        Solutions</a></li>
                                <li class="nav-item"><a routerLink="/vr-solutions" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">VR
                                        Solutions</a></li>
                                <li class="nav-item"><a routerLink="/mr-solutions" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">MR
                                        Solutions</a></li>
                                <li class="nav-item"><a routerLink="/metaverse" class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Metaverse</a></li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Industries</a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/aviation" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Aviation</a>
                                </li>
                                <li class="nav-item"><a routerLink="/education" class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Education</a></li>
                                <li class="nav-item"><a routerLink="/healthcare" class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Healthcare</a></li>
                                <li class="nav-item"><a routerLink="/manufacturing" class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Manufacturing</a></li>
                                <li class="nav-item"><a routerLink="/oil&gas" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Oil & Gas</a></li>
                                <li class="nav-item"><a routerLink="/real-estate" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Real
                                        Estate</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">SNIP-X Platform</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/about-platform" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">About
                                        Platform</a></li>

                                <!-- <li class="nav-item"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Platform Flow</a></li> -->

                                <li class="nav-item"><a routerLink="/tms-portal" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">TMS
                                        Portal</a></li>

                                <li class="nav-item"><a routerLink="/snip-x-products" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">SNIP-X
                                        Products</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Company</a>

                            <ul class="dropdown-menu">

                                <li class="nav-item">
                                    <a routerLink="/about" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">About</a>
                                </li>

                                <li class="nav-item"><a routerLink="/resources" class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Resources</a></li>

                                <li class="nav-item"><a routerLink="/news&press" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">News and
                                        Press Release</a></li>



                                <li class="nav-item"><a routerLink="/careers" cl ass="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Careers</a>
                                </li>
                            </ul>
                        </li>

                        <!-- <li class="nav-item"><a routerLink="/contact" class="nav-link">Contact </a></li> -->
                    </ul>
                </div>

                <div class="nav-btn">
                    <a routerLink="/contact" class="box-btn">Contact</a>
                </div>
            </nav>
        </div>
    </div>
</div>

<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css">
<a href="https://api.whatsapp.com/send?phone=+97317877900&text=Hi" class="float" target="_blank">
    <i class="fa fa-whatsapp my-float"></i>
</a>