
<div class="page-title-area">
    <img src="assets/images/manufacturing.jpg" style="height: 700px; width: 100%; position: relative; z-index: -1;">
    <div class="container" style="position: relative; margin-top: -35%;">
        <div class="page-title-content">
            <h2 style="font-size: 50px;color: blue;">Manufacturing</h2>
            <ul>
                <li><a routerLink="/" style="color: black;">Home   </a></li>
                <li class="active" style="color: black;">Manufacturing</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="technology-area ptb-100 bg-color">
    <div class="container">
        <div class="row align-items-center choose-c">
            <div class="col-lg-6">
                <div>
                    <img src="assets/images/manu1.webp"  style="border-radius: 8%;" alt="choose" />

                    <!-- <div class="technology-video">
                        <button type="button" class="video-btn p-0 border-0" (click)="openPopup()">
                            <i class='bx bx-play' ></i>
                        </button>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-6">
                <div class="technology-content">
                    <h2>Overview:</h2>
                    <p>Approximately 80 to 90% of all industrial accidents are attributed to human error, leading to significant consequences such as loss of life, property damage, environmental harm, regulatory violations, and a damaged reputation.

                        According to reports, industrial manufacturers encounter around 800 hours of unplanned outages every year, resulting in an estimated annual cost of $50 billion. About 42% of this downtime can be traced back to equipment failure caused by improper maintenance, leading to additional expenses for repairs and replacements.
                        
                        To remain competitive, companies must prioritize safe, reliable, and efficient operations, which necessitate a highly skilled and capable workforce. Practical learning plays a vital role in workers' skill development, with around 70% of their knowledge and expertise being acquired through experiential learning. While robust On-the-Job Training (OJT) programs are seen as a solution to these challenges, they come with significant expenses, logistical complexities, and inherent risks.</p>
                    <a routerLink="/contact" class="box-btn">Contact Us</a>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="home-case pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Industries</span>
            <h2>SOLUTIONS FOR MANUFACTURING INDUSTRY</h2>
        </div>
        <div class="case">
            <ul class="nav-tabset">
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab1'}">
                    <span (click)="switchTab($event, 'tab1')">
                       Manufacturing
                    </span>
                </li>
                
                
            </ul>
            
           
        </div>
        
    </div>
</section>

<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-right: 10%;">
                    <div class="company-tittle">
                        <span>SNIP-X</span>
                        <h2>Experiential Learning</h2>
                        <p>Virtual Reality Training Simulators offer an immersive and scenario-based learning experience, providing engineers and technicians with hands-on training in a safe and risk-free virtual environment. Through these simulators, critical skills can be learned, and individuals can assess and improve their abilities in handling high-risk equipment and tools, all without physically entering the Process Plant or Shop Floor. This technology allows learners to fully immerse themselves in realistic training scenarios, providing a safe space to practice and make mistakes without facing any real-world consequences.
                            </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/manu5.jpeg" alt="company" style="width: 100%;" />
                </div>
            </div>
        </div>
    </div>
</section>
<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/manu4.jpeg" alt="company" style="width: 100%;"/>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-left: 10%;">
                    <div class="company-tittle">
                        <span>SNIP-X</span>
                        <h2>Gamification</h2>
                        
                        <p>VR education introduces an element of fun into the learning process. Thoughtfully designed educational games in virtual reality provide learners with clear objectives, a sense of accomplishment, and rewards, which serve as strong motivators. This encouragement helps them stay focused and determined to achieve their learning goals. Moreover, the application of motivational techniques derived from gaming in non-game contexts is known as Gamification. By implementing Gamification, learning becomes engaging, captivating, and more accessible than ever before. Learners are drawn into the learning experience, making it interesting and enjoyable, thus enhancing their overall educational journey.
                            </p>
                    </div>
                </div>
            </div>

            
        </div>
    </div>
</section>
<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-right: 10%;">
                    <div class="company-tittle">
                        <span>SNIP-X</span>
                        <h2>Quick Results</h2>
                        
                        <p>Virtual Reality (VR) training has the remarkable ability to rapidly develop competent and self-assured employees, providing them with the knowledge and experience accumulated over decades in a significantly shorter period. This leads to substantial time savings on training, bypassing the need for years of traditional instruction.

                            According to PWC's latest study, VR training proves to be four times faster and four times more focused compared to conventional classroom training. The immersive nature of VR allows learners to concentrate more effectively, maximizing their engagement and absorption of information.
                            
                            Additionally, VR training creates a stronger emotional connection with the material, making the learning experience 3.75 times more impactful. This emotional resonance can lead to a 40% improvement in learners' confidence as they practice and apply their skills in realistic and risk-free virtual scenarios. Overall, VR training demonstrates its potential to revolutionize the learning process and enhance employee development significantly.
                            </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/manufacturing2.jpg" alt="company" style="width: 100%;"/>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/manu2.webp" alt="company" style="width: 100%;"/>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-left: 10%;">
                    <div class="company-tittle">
                        <span>SNIP-X</span>
                        <h2>VR HSE & Emergency Response</h2>
                        
                        <p>Our Scenario-based Fire, Safety, and Emergency Response VR training is designed to optimize employees' effectiveness in handling mission-critical situations. The training program encompasses both individual and collaborative execution of the Emergency Response Plan (ERP), taking into account various physiological and psychological human factors.

                            Through immersive scenarios, employees can experience realistic emergency situations, allowing them to practice their response and decision-making skills in a safe virtual environment. By simulating different scenarios, our VR training ensures that employees are well-prepared to handle a wide range of emergency situations.
                            
                            The training also focuses on understanding and managing the physiological and psychological factors that can influence human performance during emergencies. This knowledge enables employees to better cope with stress, make quick and accurate decisions, and effectively coordinate their actions as a team.
                            
                            By leveraging the power of VR technology, our training program enhances the learning experience, promotes active engagement, and equips employees with the confidence and skills they need to respond effectively to any fire, safety, or emergency situation they may encounter.
                            </p>
                    </div>
                </div>
            </div>

            
        </div>
    </div>
</section>
<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-right: 10%;">
                    <div class="company-tittle">
                        <span>SNIP-X</span>
                        <h2>MR Remote Expert - Assistance</h2>
                        
                        <p>The utilization of Remote Expert interactive video support through MR-Goggles introduces exciting opportunities for accessing facilities that are not readily reachable. This technology proves highly valuable in situations where personnel cannot be immediately mobilized to the site.

                            By leveraging Mixed Reality (MR) goggles, remote experts can connect with on-site personnel in real-time, providing guidance and support through interactive video communication. This enables experts to virtually inspect and troubleshoot issues without physically being present, significantly reducing response times and costs associated with travel.
                            
                            In scenarios where facilities are located in remote or challenging-to-reach locations, this innovative solution proves indispensable. The on-site personnel can collaborate seamlessly with remote experts, who can visualize the environment and offer step-by-step instructions, making repairs, maintenance, or troubleshooting tasks more efficient and accurate.
                            </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/manu3.webp" alt="company" style="width: 100%;" />
                </div>
            </div>
        </div>
    </div>
</section>




<section class="home-service-area ptb-100">
    <div class="container">
        

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/Picture1.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Faster Training
                        </h3>
                        
                          
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/Picture2.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Reduce errors
                        </h3>
                        
                          
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/Picture3.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Reduce Training Cost
                        </h3>
                        
                          
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/Picture4.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Safe & Controlled Environment</h3>
                        
                          
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/Picture5.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Improved Compliance
                        </h3>
                        
                          
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/Picture6.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Accurate KPI
                        </h3>
                        
                          
                    </div>
                </div>
            </div>


            <section class="home-contact-area home-2-contact pb-100" style="margin-top: 5%;">
                <div class="container">
                    <div class="section-title">
                        <span>Contact Us</span>
                        <h2>Let Us Know About Your Project Idea!</h2>
                        <p>It is a long established fact that a reader will be distracted by the rea dable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more.</p>
                    </div>
            
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="content">
                                <form id="contactForm">
                                    <div class="row">
                                        <div class="col-lg-12 col-sm-12">
                                            <div class="form-group">
                                                <input type="text" name="name" id="name" class="form-control" required placeholder="Your Name" />
                                            </div>
                                        </div>
            
                                        <div class="col-lg-12 col-sm-12">
                                            <div class="form-group">
                                                <input type="email" name="email" id="email" class="form-control" required placeholder="Your Email" />
                                            </div>
                                        </div>
            
                                        <div class="col-lg-12 col-sm-12">
                                            <div class="form-group">
                                                <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Your Phone" />
                                            </div>
                                        </div>
            
                                        <div class="col-lg-12 col-sm-12">
                                            <div class="form-group">
                                                <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Subject" />
                                            </div>
                                        </div>
            
                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-group">
                                                <textarea name="message" class="form-control" id="message" cols="30" rows="5" required placeholder="Your Message"></textarea>
                                            </div>
                                        </div>
            
                                        <div class="col-lg-12 col-md-12">
                                            <button type="submit" class="default-btn page-btn box-btn">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
            
                        <div class="col-lg-6 col-md-6">
                            <div class="contact-img contact-img-2">
                                <img src="assets/images/contact-img2.jpg" alt="contact" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>