
<div class="page-title-area">
    <img src="assets/images/educationx.jpg" style="height: 700px; width: 100%; position: relative; z-index: -1;">
    <div class="container" style="position: relative; margin-top: -35%;">
        <div class="page-title-content">
            <h2 style="font-size: 50px;">Education</h2>
            <ul>
                <li><a routerLink="/" style="color: black;">Home   </a></li>
                <li class="active" >Education</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="technology-area ptb-100 bg-color">
    <div class="container">
        <div class="row align-items-center choose-c">
            <div class="col-lg-6">
                <div>
                    <img src="assets/images/education1.jpg"  style="height: 550px; width: 85%; border-radius: 8%;" alt="choose" />

                    <!-- <div class="technology-video">
                        <button type="button" class="video-btn p-0 border-0" (click)="openPopup()">
                            <i class='bx bx-play' ></i>
                        </button>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-6">
                <div class="technology-content">
                    <h2>Overview:</h2>
                    <p>By visualising the virtual and physical worlds, AR,VR, and MR provides interactive and immersive educational content, allowing students to engage with realistic simulations, 3D models, and interactive environments. This innovative approach fosters active learning, boosts student engagement, and facilitates a deeper understanding of complex concepts. Through MR, educators can create dynamic and personalized learning experiences that cater to individual learning styles, ultimately revolutionizing the way knowledge is imparted and absorbed in the modern classroom.</p>
                    <a routerLink="/contact" class="box-btn">Contact Us</a>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="section-title" style="margin-top: 5%;">
    <span></span>
    <h2 style="font-size: 400%;">K12 SCHOOL EDUCATION
    </h2>
</div>
<section class="home-case pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Education</span>
            <h2>THE PROBLEM</h2>
            <h3>EDUCATION IS FACING MULTIPLE CHALLENGES
            </h3>
        </div>
        <div class="case">
            <ul class="nav-tabset">
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab1'}">
                    <span (click)="switchTab($event, 'tab1')">
                        Education
                    </span>
                </li>
                
                
            </ul>
            
           
        </div>
        
    </div>
</section>

<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-right: 10%;">
                    <div class="company-tittle">
                        <span>SNIP-X</span>
                        <h2>Education Problem
                        </h2>
                        
                        <p>In today's rapidly evolving educational landscape, learners exhibit distinct characteristics, being highly social, adept multitaskers, but often grappling with short attention spans. However, the future job market demands a skill set rooted in problem-solving, critical thinking, and collaboration. To bridge this gap effectively, educators must leverage learners' social nature by encouraging collaborative learning environments that foster teamwork and communication. Additionally, teaching methods should incorporate interactive problem-solving activities, encouraging critical thinking to pique students' interest. <br>
                            Learner's today are Social, Multi-taskers & have Short-attention span <br>
                            Future jobs require Problem Solving, Critical Thinking and Collaboration <br>
                            </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/edu2.jpg" alt="company" style="width: 100%;" />
                </div>
            </div>
        </div>
    </div>
</section>
<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/edu3.png" alt="company" style="width: 100%;"/>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-left: 10%;">
                    <div class="company-tittle">
                        <span>SNIP-X</span>
                        <h2>VR/AR Problem
                        </h2>
                        
                        <p>The slow adoption of Virtual Reality (VR) and Augmented Reality (AR) in education can be attributed to several key reasons. First, the high cost of VR/AR devices poses a significant barrier for many educational institutions, making it challenging to implement these technologies on a larger scale. Second, the lack of readily available AR/VR content assets tailored to specific educational needs hinders the integration of these technologies into curriculums. Many institutions find it difficult to develop or access appropriate content that aligns with their teaching objectives. As a result, despite initial interest and pilot programs, the majority of education institutions have struggled to progress beyond the experimental stage in incorporating VR/AR into their regular teaching methods. <br>
                            82%  of Education institutions that tried VR/AR have not moved beyond pilot *. <br>

                        </p>
                    </div>
                </div>
            </div>

            
        </div>
    </div>
</section>
<div class="section-title">
    <span>Education</span>
    <h2>LEARNING MODES</h2>
    
</div>

<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/edu6.jpg" alt="company" style="width: 100%;"/>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-left: 10%;">
                    <div class="company-tittle">
                        <span>SNIP-X</span>
                        <h2>Virtual Reality Mode
                        </h2>
                        
                        <p>Utilizing cutting-edge technology, the integration of Virtual Reality (VR) into the classroom setting offers unparalleled levels of immersion and engagement for learners. By harnessing virtual glasses like the Homido Pro, Google Cardboard, and other similar devices, students can effortlessly transform their smartphones or tablets into gateways to a mesmerizing 3D learning experience. Through this innovative approach, abstract concepts come to life, and complex ideas are simplified, enabling students to grasp difficult subjects with ease. The hands-on, interactive nature of VR lessons fosters a deeper understanding and retention of knowledge, as learners actively participate in the educational process. This transformative educational tool undoubtedly paves the way for an exciting and effective future of learning. <br>

                        </p>
                    </div>
                </div>
            </div>

            
        </div>
    </div>
</section>
<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-right: 10%;">
                    <div class="company-tittle">
                        <span>SNIP-X</span>
                        <h2>Augmented Reality Mode
                        </h2>
                        
                        <p>This groundbreaking educational application enables learners to interact and visualize pre-defined 3D models effortlessly using their readily available tablets or smartphones. With the use of image marker-less tracking technology, the app operates in real-time, seamlessly generating relevant 3D models within free space. By eliminating the need for physical markers, students can freely explore and manipulate virtual objects, fostering a truly immersive and hands-on learning experience. Whether studying complex anatomical structures, exploring historical artifacts, or delving into intricate architectural designs, this accessible and intuitive tool revolutionizes the way knowledge is absorbed. Empowering learners to engage with interactive 3D content at their own pace, the app opens up exciting possibilities for personalized and effective learning in various educational settings.
                            </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/edu5.jpg" alt="company" style="width: 100%;" />
                </div>
            </div>
        </div>
    </div>
</section>

<section class="technology-area ptb-100 bg-color">
    <div class="container">
        <div class="row align-items-center choose-c">
            

            <div class="col-lg-6">
                <div class="technology-content">
                    <h2>BENEFITS:</h2>
                    <p>1. Learn Faster, Remember Longer & Decide Better <br>
                        2. Improves the Retention Rate, Test Scores, Attention & Engagement level of students in class. <br>
                        3. Empowers & encourage students to learn “ANYTIME, ANYWHERE”. <br>
                        4. Curiosity based engaging & interactive learning <br>
                        5. Immersive & gamification experience of hard-to-understand topics. <br> 
                        6. Conduct and simulate science experiments in a safer & controlled Environment virtually <br>
                        </p>
                    <a routerLink="/contact" class="box-btn">Contact Us</a>
                </div>
            </div>
            <div class="col-lg-6">
                <div>
                    <img src="assets/images/edu10.jpg"  style="height: 550px; width: 85%; border-radius: 8%;" alt="choose" />

                    <!-- <div class="technology-video">
                        <button type="button" class="video-btn p-0 border-0" (click)="openPopup()">
                            <i class='bx bx-play' ></i>
                        </button>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</section>
<div class="section-title">
    <span></span>
    <h2>AR/VR WILL DOMINATE ADVANCED EDTECH SPENDING
    </h2>
</div>

<div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img src="assets/images/edu11.png" class="d-block w-100" alt="Slide 1">
      </div>
      <div class="carousel-item">
        <img src="assets/images/edu12.png" class="d-block w-100" alt="Slide 2">
      </div>
      
      
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>

  <div class="section-title" style="margin-top: 5%;">
    <span></span>
    <h2 style="font-size: 400%;">HIGHER EDUCATION
    </h2>
</div>
<section class="technology-area ptb-100 bg-color">
    <div class="container">
        <div class="row align-items-center choose-c">
            

            <div class="col-lg-6">
                <div class="technology-content">
                    <h2>WHY AR-VR-MR
                    </h2>
                    <p>1. Creating local JOBS in Disruptive Technologies across Industries like Aviation, Education, Defense, Governments & Public Sectors, Manufacturing, Oil & Gas, etc. <br>
                        2. Building Bahrain as an Epicenter for Future-Tech catering entire GCC region. <br>
                        3. Knowledge Transfer, Skills Upgrade, and preparation for Digital sector carriers. <br>
                        4. Providing work experience and Industry Projects for students, there by increasing Employability factor. <br>
                        
                        </p>
                    <a routerLink="/contact" class="box-btn">Contact Us</a>
                </div>
            </div>
            <div class="col-lg-6">
                <div>
                    <img src="assets/images/edu1.jpg"  style="height: 550px; width: 85%; border-radius: 8%;" alt="choose" />

                    <!-- <div class="technology-video">
                        <button type="button" class="video-btn p-0 border-0" (click)="openPopup()">
                            <i class='bx bx-play' ></i>
                        </button>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</section>
<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/edu14.png" alt="company" style="width: 100%;"/>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-left: 10%;">
                    <div class="company-tittle">
                        <span>SNIP-X</span>
                        <h2>METAVERSE INCUBATION HUB 
                        </h2>
                        
                        <p>The Metaverse Incubation Hub serves as a dynamic and collaborative space, nurturing the growth of ideas from their inception as basic concepts to the realization of viable prototypes and thriving business start-ups. With a primary focus on fostering entrepreneurship and cultivating the development of novel products and services, this innovative facility provides a supportive ecosystem that fuels creativity and innovation during the critical start-up phase. Equipped with state-of-the-art resources and mentorship programs, aspiring entrepreneurs and visionaries receive invaluable guidance and support to transform their ideas into market-ready solutions. By creating an environment that encourages experimentation and collaboration, the Metaverse Incubation Hub stands as a beacon of opportunity, propelling ambitious ventures toward success in the ever-evolving digital landscape.
                        </p>
                    </div>
                </div>
            </div>

            
        </div>
    </div>
</section>
<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-right: 10%;">
                    <div class="company-tittle">
                        <span>SNIP-X</span>
                        <h2>XR CDC
                        </h2>
                        
                        <p>The XR Content Development Centre offers a transformative learning experience for students, focusing on the cutting-edge realms of XR and the Metaverse. Through this program, learners delve into the fundamental principles of XR development engines and software, gaining hands-on experience in the practical application of 3D graphics, OpenGL ES, AR, VR, and MR technologies. By mastering these essential skills, students become adept at designing and developing immersive Metaverse applications and solutions. This comprehensive curriculum empowers individuals to unlock the vast potential of XR, enabling them to create captivating and interactive experiences that transcend traditional boundaries. With a strong foundation in this revolutionary medium, students are well-prepared to drive innovation and shape the future of the digital landscape.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/edu13.jpg" alt="company" style="width: 100%;" />
                </div>
            </div>
        </div>
    </div>
</section>
<section class="feature-area bg-color ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="contnet">
                    <div class="feature-tittle">
                        <span>Education</span>
                        <h2>XR DEVELOPMENT LAB
                        </h2>
                        <p>The XR Development Lab is a comprehensive and immersive training program that offers a complete workflow training for aspiring XR developers. Through a dynamic and modular approach, participants engage in hands-on activities and assessments, fostering a collaborative environment where they build and create together with expert guidance and observations.</p>
                    </div>
                    <ul>
                        <li><i class="flaticon-correct"></i> Complete XR workflow training</li>
                        <li><i class="flaticon-correct"></i> Modular build and assessed together with observations</li>
                        <li><i class="flaticon-correct"></i> Dynamic training structure</li>
                        <li><i class="flaticon-correct"></i> Mixed group of trainers / experts</li>
                        <li><i class="flaticon-correct"></i> Building the required set of skills</li>
                    </ul>
                    <a routerLink="/contact" class="box-btn">Lets Talk!</a>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="feature-img">
                    <img src="assets/images/feature-img.png" alt="feature" />
                </div>
            </div>
        </div>
    </div>
</section>

<section class="choose-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Choose Snip_X</span>
            <h2>XR ENTREPRENEUR/TRAINING PROGRAM</h2>
            <p>KNOWLEDGE TRANSFER & CERTIFICATION</p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="choose-img">
                    <img src="assets/images/edu20.jpg"  style="border-radius: 7%;"  alt="choose" />
                </div>
            </div>

            <div class="col-lg-6">
                <div class="choose-content">
                    <div class="faq-accordion">
                        <div class="faq-item" *ngFor="let item of accordionItems; let i = index;">
                            <div class="faq-header" (click)="toggleAccordionItem(item)">
                                {{item.title}}
                                <i class='bx' [ngClass]="{'bx-plus': !item.open, 'bx-x': item.open}"></i>
                            </div>
                            <div class="faq-content" [hidden]="!item.open">
                                <p>{{item.content}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</section>
<section class="technology-area ptb-100 bg-color">
    <div class="container">
        <div class="row align-items-center choose-c">
            

            <div class="col-lg-6">
                <div class="technology-content">
                    <h2>BENIFITS
                    </h2>
                    <h3>FOR STUDENTS
                    </h3>
                    <p>Skill Upgrade – 3D, Augmented-Virtual-Mixed Reality <br>
                        Technical Development Trainings <br>
                        Industry Interface projects <br>
                        Work Experience & Internships <br>
                        
                        </p>
                        <h3>FOR BAHRAIN POLYTECHNIC
                        </h3>
                        <p>Incubation Programs <br>
                            Start-up Facilitation <br>
                            In-house Cutting-edge Technology stack & Capabilities <br>
                            Research & Development <br>
                            
                            
                            </p>
                            <h3>FOR BAHRAIN
                            </h3>
                            <p>Bahrainisation <br>
                                Start-Up Culture <br>
                                Regional Metaverse Incubation Hub <br>
                                Developing Skilled Smart Workforce <br>
                                Introducing Industry 4.0 Initiative through Young Workforce <br>
                                Boost to Digital Economy by brining Metaverse projects to Bahrain <br>
                                
                                </p>
                    <a routerLink="/contact" class="box-btn">Contact Us</a>
                </div>
            </div>
            <div class="col-lg-6">
                <div>
                    <img src="assets/images/edu22.png"  style="height: 550px; width: 85%; border-radius: 8%;" alt="choose" />

                    <!-- <div class="technology-video">
                        <button type="button" class="video-btn p-0 border-0" (click)="openPopup()">
                            <i class='bx bx-play' ></i>
                        </button>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</section>

<div class="section-title" style="margin-top: 5%;">
    <span></span>
    <h2>FLOOR PLANS & VISUALS
    </h2>
</div>


<section class="technology-area ptb-100 bg-color">
    <div class="container">
        <div class="row align-items-center choose-c"  >
            

            <div class="col-lg-6">
                <div>
                    <img src="assets/images/x2.jpg"  style="height: 550px; width: 100%; border-radius: 8%;" alt="choose" />

                    <!-- <div class="technology-video">
                        <button type="button" class="video-btn p-0 border-0" (click)="openPopup()">
                            <i class='bx bx-play' ></i>
                        </button>
                    </div> -->
                </div>
            </div>
            <div class="col-lg-6">
                <div>
                    <img src="assets/images/x3.jpg"  style="height: 550px; width: 100%; border-radius: 8%;" alt="choose" />

                    <!-- <div class="technology-video">
                        <button type="button" class="video-btn p-0 border-0" (click)="openPopup()">
                            <i class='bx bx-play' ></i>
                        </button>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</section>


            <section class="home-contact-area home-2-contact pb-100" style="margin-top: 5%;">
                <div class="container">
                    <div class="section-title">
                        <span>Contact Us</span>
                        <h2>Let Us Know About Your Project Idea!</h2>
                        <p>It is a long established fact that a reader will be distracted by the rea dable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more.</p>
                    </div>
            
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="content">
                                <form id="contactForm">
                                    <div class="row">
                                        <div class="col-lg-12 col-sm-12">
                                            <div class="form-group">
                                                <input type="text" name="name" id="name" class="form-control" required placeholder="Your Name" />
                                            </div>
                                        </div>
            
                                        <div class="col-lg-12 col-sm-12">
                                            <div class="form-group">
                                                <input type="email" name="email" id="email" class="form-control" required placeholder="Your Email" />
                                            </div>
                                        </div>
            
                                        <div class="col-lg-12 col-sm-12">
                                            <div class="form-group">
                                                <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Your Phone" />
                                            </div>
                                        </div>
            
                                        <div class="col-lg-12 col-sm-12">
                                            <div class="form-group">
                                                <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Subject" />
                                            </div>
                                        </div>
            
                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-group">
                                                <textarea name="message" class="form-control" id="message" cols="30" rows="5" required placeholder="Your Message"></textarea>
                                            </div>
                                        </div>
            
                                        <div class="col-lg-12 col-md-12">
                                            <button type="submit" class="default-btn page-btn box-btn">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
            
                        <div class="col-lg-6 col-md-6">
                            <div class="contact-img contact-img-2">
                                <img src="assets/images/contact-img2.jpg" alt="contact" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>