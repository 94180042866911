<div class="page-title-area">
    <img src="assets/images/re9.png" style="height: 700px; width: 100%; position: relative; z-index: -1;">
    <div class="container" style="position: relative; margin-top: -35%;">
        <div class="page-title-content">
            <h2 style="font-size: 50px;">Real Estate</h2>
            <ul>
                <li><a routerLink="/">Home   </a></li>
                <li class="active">Real Estate</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>



<section class="technology-area ptb-100 bg-color">
    <div class="container">
        <div class="row align-items-center choose-c">
            <div class="col-lg-6">
                <div>
                    <img src="assets/images/ree.jpg"  style="height: 550px; width: 85%; border-radius: 8%;" alt="choose" />

                    
                </div>
            </div>

            <div class="col-lg-6">
                <div class="technology-content">
                    <h2>Overview:</h2>
                    <p>The real estate industry is constantly evolving, and meeting customer demands is crucial for success. As customers' interests in properties, lands, and real estate change daily, businesses must adapt to stay competitive. Immersive digital technologies like augmented reality (AR) and virtual reality (VR) have the potential to revolutionize the way real estate operates, offering engaging and interactive experiences. By creating immersive artificial realities, the industry can become more versatile and dynamic, enhancing property showcases, virtual tours, and personalized experiences for potential buyers. This transformation will redefine the market, empowering businesses to stay at the forefront of innovation and cater to the unique preferences of modern consumers.</p>
                    <a routerLink="/contact" class="box-btn">Contact Us</a>
                </div>
            </div>
        </div>
    </div>
</section>



<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-right: 10%;">
                    <div class="company-tittle">
                        <span>SNIP-X</span>
                        <h2>VIRTUAL TOURS OR WALKTHROUGH
                        </h2>
                        <h3>Interactive Walkthrough (Augmented Reality)
                        </h3>
                        <p>
                            Interactive walkthroughs in virtual reality offer users the freedom to explore and engage actively with home tours. By providing the option to choose their path and interact with elements through intuitive hotspots in their field of view, these tours create a more immersive experience. While they are undoubtedly more efficient than guided tours, their development complexity is significantly higher. Nevertheless, the effort is justified as interactive VR tours empower potential buyers to deeply connect with properties, resulting in better-informed decisions. The real estate industry's adoption of such cutting-edge technology showcases its commitment to embracing innovation and meeting the evolving demands of tech-savvy customers.
                            </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/re1.jpg" alt="company" style="width: 100%;border-radius: 7%;" />
                </div>
            </div>
        </div>
    </div>
</section>
<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/re2.jpg" alt="company" style="width: 100%;border-radius: 7%;"/>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-left: 10%;">
                    <div class="company-tittle">
                        <span>SNIP-X</span>
                        <h2>VIRTUAL TOURS OR WALKTHROUGH</h2>
                        <h3>Guided Walkthrough (Virtual Reality)
                        </h3>
                        <p>
                            Guided walkthroughs in the real estate industry are pre-recorded videos captured through a panoramic camera, showcasing properties in a 360-degree format. With minimal reliance on complex rendering or programming, these immersive videos are designed to be experienced through VR headsets. By providing potential buyers with a lifelike and interactive view of properties, guided walkthroughs offer a dynamic and engaging way to explore real estate listings. This technology enables viewers to virtually step into spaces, gaining a comprehensive understanding of the property's layout and features, revolutionizing the property viewing experience and enhancing decision-making for buyers. </p>
                    </div>
                </div>
            </div>

            
        </div>
    </div>
</section>
<section class="home-company-area">
    <div class="container">
        
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-right: 10%;">
                    <div class="company-tittle">
                        <span>SNIP-X</span>
                        <h2>3D & 360º Virtual Tour 
                        </h2>
                       
                        <p>
                            3D & 360° VR tours are hyper-realistic and immersive way of showcasing a property in no time while attracting an immense pool of potential buyers. Real estate developers and property managers can benefit hugely by way of Virtual Tours. 

                            Sparking the curiosities of potential buyers, virtual tours for real estate showcase and highlight the key areas of the properties in a captivating way. The immersive experience provides a competitive advantage in the market while reducing costs drastically.
                                
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/re3.jpg" alt="company" style="width: 100%;"/>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/re4.png" alt="company" style="width: 100%;"/>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-left: 10%;">
                    <div class="company-tittle">
                        <span>SNIP-X</span>
                        <h2>VIRTUAL STAGING

                        </h2>
                        
                        <p>Virtual staging is an innovative solution in the real estate industry, involving the use of graphic editors to design interior spaces for vacant properties, enabling clients to visualize the potential of the space. By integrating AR/VR technology, realtors can create highly realistic images of homes for sale. This approach significantly reduces costs compared to traditional staging methods while providing customization options to cater to individual client preferences. This game-changing technology not only enhances the overall buying experience but also streamlines the sales process, allowing potential buyers to envision themselves living in the property, leading to quicker and more informed decisions.
</p>                            
                    </div>
                </div>
            </div>

            
        </div>
    </div>
</section>
<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-right: 10%;">
                    <div class="company-tittle">
                        <span>SNIP-X</span>
                        <h2>ARCHITECTURAL VISUALIZATION

                        </h2>
                        
                        <p>
                            Real estate developers and agents have long faced the challenge of marketing properties that are yet to be constructed. In the past, 3D models were utilized to help clients visualize potential buildings, while expensive showrooms displayed apartment interiors for marketing. However, the advent of AR/VR technology now offers a cost-effective and remarkably immersive alternative. By creating fully developed three-dimensional models, AR/VR enables buyers to envision the final product with clarity. This transformative approach empowers the industry to provide clients with a comprehensive understanding of properties, fostering enhanced engagement and informed decision-making, ultimately revolutionizing how real estate projects are marketed and sold.
                            </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/re5.png" alt="company" style="width: 100%;" />
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/re6.png" alt="company" style="width: 100%;"/>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-left: 10%;">
                    <div class="company-tittle">
                        <span>SNIP-X</span>
                        <h2>PRE-CONSTRUCTION VISUALIZATION
                        </h2>
                        
                        <p>
                            Virtual reality (VR) is a potent pre-construction visualization tool, enabling architects, engineers, and construction professionals to experience proposed buildings like never before. Through VR models, they can identify and address potential issues in the design phase, enhancing efficiency. Improved communication among team members is facilitated by immersive collaboration in a shared virtual space, fostering better decision-making. Moreover, stakeholders can gain valuable insights, promoting buy-in and alignment with project goals. By leveraging VR technology, the construction industry can streamline processes, reduce costs, and deliver exceptional results.
</p>                            
                    </div>
                </div>
            </div>

            
        </div>
    </div>
</section>
<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-right: 10%;">
                    <div class="company-tittle">
                        <span>SNIP-X</span>
                        <h2>VIRTUAL COMMERCE
                        </h2>
                        
                        <p>
                            This groundbreaking application builds upon VR Home Tours and Staging, empowering potential homebuyers to explore online stores while customizing elements within their VR tours. With a fully immersive and personalized experience, clients can effortlessly compare and select new furniture and design items using VR headsets. Moreover, the ability to purchase showcased pieces directly from the VR tours enhances convenience and engagement. The integration of AR/VR in real estate applications addresses the emotional aspect of property buying, enabling buyers to connect emotionally with properties and facilitating decision-making. For sellers and agents, this technology proves invaluable in enhancing marketing strategies and attracting discerning buyers.                            </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/re7.png" alt="company" style="width: 100%;" />
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/re8.png" alt="company" style="width: 100%;"/>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-left: 10%;">
                    <div class="company-tittle">
                        <span>SNIP-X</span>
                        <h2>METAVERSE SOLUTIONS

                        </h2>
                        
                        <p>
                            Enter the Metaverse, where avatars come alive, virtual spaces await exploration, and real-time interactions connect users. Our end-to-end virtual world solutions unlock this realm, ensuring active user participation in the digital landscape. With our expertise, organizations can establish and manage their custom virtual spaces, expanding brand presence, offerings, and creativity to maximize engagement. Embracing the Metaverse opens doors to novel experiences, thriving communities, and vibrant economies, revolutionizing human interactions. Seize the potential of this transformative platform to shape the future of connectivity and immersive experiences, forging new frontiers in human interaction and enterprise advancement.
                        </p>                            
                    </div>
                </div>
            </div>

            
        </div>
    </div>
</section>

<div class="section-title">
    <span></span>
    <h2 style="margin-top: 15%;">BENEFITS & VALUE ADDITION</h2>
</div>


<section class="home-service-area ptb-100">
    <div class="container">
        

        <div class="row">
            <div class="company-img" style="margin-left:2% ;margin-bottom: 10%;">
                <img src="assets/images/rex.png" alt="company" style="width: 100%;" />
            </div>
            


            <section class="home-contact-area home-2-contact pb-100">
                <div class="container">
                    <div class="section-title">
                        <span>Contact Us</span>
                        <h2>Let Us Know About Your Project Idea!</h2>
                        <p>It is a long established fact that a reader will be distracted by the rea dable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more.</p>
                    </div>
            
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="content">
                                <form id="contactForm">
                                    <div class="row">
                                        <div class="col-lg-12 col-sm-12">
                                            <div class="form-group">
                                                <input type="text" name="name" id="name" class="form-control" required placeholder="Your Name" />
                                            </div>
                                        </div>
            
                                        <div class="col-lg-12 col-sm-12">
                                            <div class="form-group">
                                                <input type="email" name="email" id="email" class="form-control" required placeholder="Your Email" />
                                            </div>
                                        </div>
            
                                        <div class="col-lg-12 col-sm-12">
                                            <div class="form-group">
                                                <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Your Phone" />
                                            </div>
                                        </div>
            
                                        <div class="col-lg-12 col-sm-12">
                                            <div class="form-group">
                                                <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Subject" />
                                            </div>
                                        </div>
            
                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-group">
                                                <textarea name="message" class="form-control" id="message" cols="30" rows="5" required placeholder="Your Message"></textarea>
                                            </div>
                                        </div>
            
                                        <div class="col-lg-12 col-md-12">
                                            <button type="submit" class="default-btn page-btn box-btn">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
            
                        <div class="col-lg-6 col-md-6">
                            <div class="contact-img contact-img-2">
                                <img src="assets/images/contact-img2.jpg" alt="contact" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>