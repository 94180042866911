<div class="page-title-area">
    <img src="assets/images/oilbkg.jpeg" style="height: 700px; width: 100%; position: relative; z-index: -1;">
    <div class="container" style="position: relative; margin-top: -35%;">
        <div class="page-title-content">
            <h2 style="font-size: 50px;">Oil & Gas</h2>
            <ul>
                <li><a routerLink="/">Home   </a></li>
                <li class="active">Oil & Gas</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>




<section class="technology-area ptb-100 bg-color">
    <div class="container">
        <div class="row align-items-center choose-c">
            <div class="col-lg-6">
                <div>
                    <img src="assets/images/oil-gas.jpg"  style="height: 550px; width: 85%; border-radius: 8%;" alt="choose" />

                    
                </div>
            </div>

            <div class="col-lg-6">
                <div class="technology-content">
                    <h2>Overview:</h2>
                    <p>The real estate industry is constantly evolving, and meeting customer demands is crucial for success. As customers' interests in properties, lands, and real estate change daily, businesses must adapt to stay competitive. Immersive digital technologies like augmented reality (AR) and virtual reality (VR) have the potential to revolutionize the way real estate operates, offering engaging and interactive experiences. By creating immersive artificial realities, the industry can become more versatile and dynamic, enhancing property showcases, virtual tours, and personalized experiences for potential buyers. This transformation will redefine the market, empowering businesses to stay at the forefront of innovation and cater to the unique preferences of modern consumers.</p>
                    <a routerLink="/contact" class="box-btn">Contact Us</a>
                </div>
            </div>
        </div>
    </div>
</section>



<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-right: 10%;">
                    <div class="company-tittle">
                        <span>SNIP-X</span>
                        <h2>TRAINING SOLUTIONS
                        </h2>
                        <p>
                            Take your work to the next level by partnering with Atyaf to achieve top-quality Training and Skill Development in the Energy Sector. We offer a wide range of VR, AR, MR Simulations and Industry 4.0 Solutions to transform your work into a seamless and flawless endeavour. Eliminate human error and defects by setting new standards of quality and training using our tools. Enhance the skill levels of all your workers, from engineers to technicians – we bring you the best platforms to Empower your Workforce to set new standards of excellence in the industry.
                            </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/oil2.png" alt="company" style="width: 100%;border-radius: 7%;" />
                </div>
            </div>
        </div>
    </div>
</section>
<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/oil3.jpeg" alt="company" style="width: 100%;border-radius: 7%;"/>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-left: 10%;">
                    <div class="company-tittle">
                        <span>SNIP-X</span>
                        <h2>HEALTH & SAFETY TRAINING</h2>
                        <h3>HSE Training 
                        </h3>
                        <p>
                            Health, Safety, and Environment (HSE) training is a crucial aspect of any organization's operations, particularly in industries where employee safety is paramount, such as manufacturing, construction, oil and gas, and healthcare. Traditional HSE training methods have proven effective, but advancements in technology have opened up new avenues for enhancing the learning experience and overall outcomes. Augmented Reality (AR), Virtual Reality (VR), and Mixed Reality (MR) are emerging as powerful tools that can revolutionize HSE training and provide numerous benefits to organizations and their employees. <br> By incorporating AR, VR, and MR technologies into HSE training programs, organizations can create safer and healthier work environments. Employees become better equipped to handle potential risks, accidents, and injuries, leading to a more proactive safety culture. Additionally, organizations may experience reduced downtime, insurance costs, and legal liabilities associated with workplace accidents.</p>
                    </div>
                </div>
            </div>

            
        </div>
    </div>
</section>
<div class="section-title">
    <span></span>
    <h2 style="margin-top: 15%;">SOLUTIONS - UPSTREAM</h2>
</div>
<section class="home-company-area">
    <div class="container">
        
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-right: 10%;">
                    <div class="company-tittle">
                        <span>SNIP-X</span>
                        <h2>AR Exploration and Reservoir Analysis 
                        </h2>
                        
                        <p>Augmented Reality (AR) in the oil and gas industry offers real-time interpretation of subsurface structures, enhancing exploration efficiency. Geoscientists use AR-enabled devices to visualize seismic data and geological models, aiding quick decision-making during exploration. AR fosters collaboration among teams, enabling shared augmented views for informed decisions. Reservoir characterization benefits from AR's dynamic integration of well logs and seismic data, deepening insights into subsurface properties. The technology mitigates drilling risks by displaying potential hazards on the terrain. AR facilitates training for geoscientists and petroleum engineers through realistic virtual environments, accelerating skill development. Overall, AR streamlines workflows, reducing data analysis time and optimizing exploration and drilling strategies for cost efficiency.
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/oil4.jpeg" alt="company" style="width: 100%;"/>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/oil5.jpeg" alt="company" style="width: 100%;"/>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-left: 10%;">
                    <div class="company-tittle">
                        <span>SNIP-X</span>
                        <h2>VR Drilling and Well Operations

                        </h2>
                        
                        <p>Virtual Reality (VR) training in the oil and gas industry revolutionizes personnel preparation for drilling operations. By offering lifelike simulations, trainees experience diverse drilling scenarios, from routine to complex tasks, improving their ability to handle real-world challenges. Incorporating haptic feedback technology provides realistic sensations, fostering muscle memory and coordination. The controlled virtual environment ensures safe learning, free from risks to personnel and expensive equipment. VR's immersive nature enhances learning retention and on-the-job performance. With reduced reliance on physical rigs, VR training leads to cost savings and flexible self-paced learning. Built-in assessment tools provide instant feedback, enabling targeted improvements. In conclusion, VR empowers confident handling of drilling procedures, enhancing operational efficiency and safety in the oil and gas industry.</p>                            
                    </div>
                </div>
            </div>

            
        </div>
    </div>
</section>
<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-right: 10%;">
                    <div class="company-tittle">
                        <span>SNIP-X</span>
                        <h2>Asset Inspection and Maintenance
                        </h2>
                        <h3>Visualization and Virtual Process Understanding</h3>
                        <p>
                            AR overlay equipment specifications, maintenance procedures, and historical performance data onto physical assets, improving field technicians' situational awareness during inspection and maintenance activities. It enables technicians to interact with digital representations of equipment, visualize hidden components, and access real-time diagnostic information, enhancing maintenance efficiency and reducing downtime.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/oil6.jpeg" alt="company" style="width: 100%;" />
                </div>
            </div>
        </div>
    </div>
</section>
<div class="section-title">
    <span></span>
    <h2 style="margin-top: 15%;">SOLUTIONS - DOWNSTREAM</h2>
</div>
<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/oil8.jpeg" alt="company" style="width: 100%;"/>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-left: 10%;">
                    <div class="company-tittle">
                        <span>SNIP-X</span>
                        <h2>VR FIELD OPERATOR TRAINING
                        </h2>
                        <h3>Startup Scenario & Emergency Planning</h3>
                        <p>
                            Our innovative approach involves building a virtual replica of equipment units, enabling field service technicians to engage with a lifelike representation. Through this technology, technicians can explore and interact with the equipment's components, gaining in-depth understanding of its operation and becoming familiar with various parts and controls. This virtual training experience offers a safe and controlled environment, allowing technicians to hone their skills, troubleshoot potential issues, and practice maintenance procedures without any real-world risks. By providing hands-on training in a virtual setting, we equip technicians with the knowledge and confidence needed to excel in their roles, ultimately enhancing efficiency and service quality in the field.</p>                            
                    </div>
                </div>
            </div>

            
        </div>
    </div>
</section>
<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-right: 10%;">
                    <div class="company-tittle">
                        <span>SNIP-X</span>
                        <h2>Supply 
                            Chain Optimization
                        </h2>
                        
                        <p>
                            By leveraging cutting-edge technologies, such as Artificial Intelligence (AI) and Internet of Things (IoT), we can overlay real-time data on transportation routes, inventory levels, and demand forecasts in the oil and gas industry. This integration enables us to streamline the movement of oil and gas products throughout the supply chain. By analyzing live data, we can identify the most efficient transportation routes, reducing delays and minimizing transit times. Real-time monitoring of inventory levels allows us to optimize storage capacity, ensuring adequate stock levels while avoiding excess inventory. Moreover, by accurately predicting demand through data-driven forecasts, we can proactively adjust supply chain operations, ensuring a smooth and agile distribution process. Ultimately, these technologies enhance overall supply chain efficiency, leading to improved operational performance and customer satisfaction.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/oil7.jpeg" alt="company" style="width: 100%;" />
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/oil9.jpeg" alt="company" style="width: 100%;"/>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-left: 10%;">
                    <div class="company-tittle">
                        <span>SNIP-X</span>
                        <h2>Plant, Monitoring, Maintenance and Assistance</h2>
                        <h3>Equipment Maintenance                        </h3>
                        <p>
                            With the Digital Twin of the facility, enjoy real-time feedback & condition of each component of every machine and know where to replace, repair, or service your machines.
</p>    
                        <h3>Remote On-Site Assistance                        </h3>  
                        <p>Configure the Digital Twin to assist operators and managers in easy access to on-site assistance, Be alerted of a potential disaster. Employees can complete complex maintenance tasks accurately with step-by-step instructions,
                        </p>                      
                    </div>
                </div>
            </div>

            
        </div>
    </div>
</section>

<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-right: 10%;">
                    <div class="company-tittle">
                        <span>SNIP-X</span>
                        <h2>DIGITAL TWIN

                        </h2>
                        
                        <p>
                            We create hyperrealistic 1-to-1 digital replicas, identical to real-life counterparts  of physical objects, system, operations and assets and entire environments, 3D designed to accurate real-life visual and functional properties, visual quality and capabilities, usability, responses and interactions for any of the industries. A digital proxy can talk to other proxies and form a whole network of intelligent devices in a digital eco-system enhancing the accuracy of predictive models. 
</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/oil10.jpeg" alt="company" style="width: 100%;" />
                </div>
            </div>
        </div>
    </div>
</section>

<div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img src="assets/images/oil11.jpeg" class="d-block w-100" alt="Slide 1" style="height: 600px;">
      </div>
      <div class="carousel-item">
        <img src="assets/images/oil12.jpeg" class="d-block w-100" alt="Slide 2" style="height: 600px;">
      </div>
      
      
      
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>

<section class="home-service-area ptb-100">
    <div class="container">
        

        
            


            <section class="home-contact-area home-2-contact pb-100">
                <div class="container">
                    <div class="section-title">
                        <span>Contact Us</span>
                        <h2>Let Us Know About Your Project Idea!</h2>
                        <p>It is a long established fact that a reader will be distracted by the rea dable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more.</p>
                    </div>
            
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="content">
                                <form id="contactForm">
                                    <div class="row">
                                        <div class="col-lg-12 col-sm-12">
                                            <div class="form-group">
                                                <input type="text" name="name" id="name" class="form-control" required placeholder="Your Name" />
                                            </div>
                                        </div>
            
                                        <div class="col-lg-12 col-sm-12">
                                            <div class="form-group">
                                                <input type="email" name="email" id="email" class="form-control" required placeholder="Your Email" />
                                            </div>
                                        </div>
            
                                        <div class="col-lg-12 col-sm-12">
                                            <div class="form-group">
                                                <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Your Phone" />
                                            </div>
                                        </div>
            
                                        <div class="col-lg-12 col-sm-12">
                                            <div class="form-group">
                                                <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Subject" />
                                            </div>
                                        </div>
            
                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-group">
                                                <textarea name="message" class="form-control" id="message" cols="30" rows="5" required placeholder="Your Message"></textarea>
                                            </div>
                                        </div>
            
                                        <div class="col-lg-12 col-md-12">
                                            <button type="submit" class="default-btn page-btn box-btn">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
            
                        <div class="col-lg-6 col-md-6">
                            <div class="contact-img contact-img-2">
                                <img src="assets/images/contact-img2.jpg" alt="contact" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>