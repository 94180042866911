import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-case-studies-details',
  templateUrl: './case-studies-details.component.html',
  styleUrls: ['./case-studies-details.component.scss']
})
export class CaseStudiesDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  // Accordion
  accordionItems = [
    {
        title: 'Training Experts',
        content: `Subject matter experts deliver professional training on location or remotely.`,
        open: false
    },
    {
        title: 'Modular Structure',
        content: `The sets of subjects can be adjusted to fit the location purpose and support the best development of required skills`,
        open: false
    },
    {
        title: 'Fundamental Skills',
        content: `The trainees build up a skill-set required for working in the interactive content creation, XR industry        `,
        open: false
    },
    {
        title: 'Intensive Sessions',
        content: `The subjects are delivered in a highly concentrated & applied manner focusing on building specific skills and knowledge for the industry.        `,
        open: false
    },
    {
      title: 'XR Systems',
      content: `The trainees have access to the full range of immersive XR systems in order to apply their knowledge & test their projects`,
      open: false
  }
];
selectedItem : any = null;
toggleAccordionItem(item:any) {
    item.open = !item.open;
    if (this.selectedItem && this.selectedItem !== item) {
        this.selectedItem.open = false;
    }
    this.selectedItem = item;
}

  // Tabs
  currentTab = 'tab1';
  switchTab(event: MouseEvent, tab: string) {
      event.preventDefault();
      this.currentTab = tab;
  }
}
