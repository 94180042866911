<section class="banner-area">
    <div class="video-container" style="background-color: black;">
        <video src="" autoplay muted loop playsinline>
        </video>
    </div>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="banner-content">
                            <h1>Trusted AR VR MR Solutions</h1>
                            <p>SNIP-X delivers state-of-the-art solutions in augmented reality, virtual reality, mixed
                                reality, metaverse, and virtual showrooms, revolutionizing industries through immersive
                                experiences and pioneering technology.</p>
                            <div class="banner-btn">
                                <a routerLink="/contact" class="box-btn">Contact Us</a>
                                <a routerLink="/about" class="box-btn border-btn">Know More</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">

                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="home-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape"></div>
        <div class="shape2"><img src="assets/images/shape/2.png" alt="shape"></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape"></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape"></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape"></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape"></div>
    </div>
</section>

<section class="home-company-area bg-color">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content">
                    <div class="company-tittle">
                        <span>Our Company</span>
                        <h2>We are more than you imagine</h2>
                        <p>Atyaf eSolutions (founded in 1995) was the pioneer and now a leading solutions provider in
                            the IT space for the Kingdom of Bahrain as their HQ, with operations and clientele spread
                            across the entire MENA region. We are a bunch of tech gigs along with few industry domain
                            experts with 25+ years of rich experience working in key industries like yours. Below is a
                            glimpse of our solution stack and capabilities for your reference.</p>
                    </div>

                    <a routerLink="/about" class="box-btn">Know More</a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/company/company-img.jpg" alt="company" />
                </div>
            </div>
        </div>
    </div>
</section>
<section class="home-service-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Smart Services</span>
            <h2>SNIP-X Provide All Kind of Tech Solutions</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s1.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Augmented Reality (AR)</h3>
                        <p>Augmented Reality (AR) is a technology that overlays digital information, such as images,
                            videos, and 3D models, onto the real world.</p>

                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s2.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Virtual Reality (VR)</h3>
                        <p>Virtual Reality (VR) is a technology that creates a fully immersive and interactive digital
                            environment, simulating real-world experiences.</p>

                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s3.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Mixed Reality (MR)</h3>
                        <p>Mixed Reality (MR) combines elements of both AR and VR to create a seamless blend of the real
                            and virtual worlds.</p>

                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s4.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Metaverse</h3>
                        <p>The Metaverse is a virtual universe that combines physical and digital realities, enabling
                            users to interact, explore, and socialize in a shared virtual space.</p>

                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s5.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Virtual Showroom</h3>
                        <p>A virtual showroom is a digital platform that allows users to explore and interact with
                            products and services in a virtual environment.</p>

                    </div>
                </div>
            </div>
            <section class="home-case pt-100 pb-70">
                <div class="container">
                    <div class="section-title">
                        <span>Industries</span>
                        <h2>Have a Look Our Work Showcase</h2>
                        <p>SNIP-X is a leading AR/VR/MR company at the forefront of immersive technology, transforming
                            industries through cutting-edge solutions and captivating experiences.</p>
                    </div>
                    <div class="case">
                        <ul class="nav-tabset">
                            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab1'}">
                                <span (click)="switchTab($event, 'tab1')">
                                    Industries
                                </span>
                            </li>
                        </ul>
                        <div class="tabs-container">
                            <div class="pane" id="tab1" *ngIf="currentTab === 'tab1'">
                                <div class="row case-list">
                                    <div class="col-lg-4 col-sm-6">
                                        <div class="single-case">
                                            <div class="case-img">
                                                <img src="assets/images/aiviation.jpg"
                                                    style="height: 13rem !important; width: 100% !important;"
                                                    alt="case" />
                                            </div>

                                            <div class="content">
                                                <h3><a routerLink="/aviation">Aviation</a></h3>
                                                <p>The aviation industry involves the operation of aircraft for
                                                    transportation, including airlines, airports, and related services.
                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-sm-6">
                                        <div class="single-case">
                                            <div class="case-img">
                                                <img src="assets/images/education.jpg"
                                                    style="height: 13rem !important; width: 100% !important;"
                                                    alt="case" />
                                            </div>

                                            <div class="content">
                                                <h3><a routerLink="/education">Education</a> </h3>
                                                <p>The education industry encompasses K-12 schools, colleges,
                                                    universities, and vocational training institutions.</p>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-sm-6">
                                        <div class="single-case">
                                            <div class="case-img">
                                                <img src="assets/images/healthcare.jpg"
                                                    style="height: 13rem !important; width: 100% !important;"
                                                    alt="case" />
                                            </div>

                                            <div class="content">
                                                <h3><a routerLink="/healthcare">Healthcare</a></h3>
                                                <p> The healthcare industry focuses on the prevention, diagnosis,
                                                    treatment, and management of illnesses and medical conditions.</p>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-sm-6">
                                        <div class="single-case">
                                            <div class="case-img">
                                                <img src="assets/images/manufacturing.jpg"
                                                    style="height: 13rem !important; width: 100% !important;"
                                                    alt="case" />
                                            </div>

                                            <div class="content">
                                                <h3><a routerLink="/manufacturing">Manufacturing</a></h3>
                                                <p>The manufacturing industry involves the production of goods through
                                                    various processes, such as assembly, fabrication, and quality
                                                    control.</p>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-sm-6">
                                        <div class="single-case">
                                            <div class="case-img">
                                                <img src="assets/images/oil-gas.jpg"
                                                    style="height: 13rem !important; width: 100% !important;"
                                                    alt="case" />
                                            </div>

                                            <div class="content">
                                                <h3><a routerLink="/oil&gas">Oil & Gas</a></h3>
                                                <p>The oil and gas industry involves the exploration, extraction,
                                                    refining, and distribution of petroleum products.</p>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-sm-6">
                                        <div class="single-case">
                                            <div class="case-img">
                                                <img src="assets/images/real-estate.jpg"
                                                    style="height: 13rem !important; width: 100% !important;"
                                                    alt="case" />
                                            </div>

                                            <div class="content">
                                                <h3><a routerLink="/real-estate">Real Estate</a></h3>
                                                <p>The real estate industry involves buying, selling, and managing
                                                    properties, including residential, commercial, and industrial
                                                    spaces.</p>

                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="case-btn text-center">
                        <p>We Have More Amazing Cases. View More </p>
                    </div>
                </div>
            </section>

            <section class="client-area ptb-100 bg-color">
                <div class="container">
                    <div class="section-title">
                        <span>Testimonials</span>
                        <h2>What Our Client’s Say</h2>
                        <p>Experience firsthand the exceptional impact of SNIP-X's virtual showroom solution, redefining
                            customer experience by enabling captivating product visualization in a whole new dimension.
                        </p>
                    </div>
                    <div class="client-wrap">
                        <owl-carousel-o [options]="clientWrap">
                            <ng-template carouselSlide>
                                <div class="single-client">
                                    <img src="assets/images/test1.png" alt="img" style="height: 70px; width: 70px;">
                                    <p>We are delighted to announce this collaboration with Atyaf eSolutions to offer
                                        AVR training solutions. We aim, through this partnership, to provide training in
                                        a safe and affordable manner using the latest technologies. This will certainly
                                        contribute to the expansion of the premium training services offered by the
                                        Academy and cement its leading global position in the aviation and logistics
                                        fields.</p>
                                    <h3>GAA CEO Captain Dhaffer Al Abbasi</h3>

                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="single-client">
                                    <img src="assets/images/test3.png" alt="img" style="height: 70px; width: 70px;">
                                    <p>Virtual Reality is a medium -- a means by which our employees can learn in a safe
                                        and immersive environment without the risk of injury -- and this is when seeing
                                        evolves to believing.
                                        Alba is well-known as one of the leading technology-driven enterprises in
                                        Bahrain that has embraced the latest digital technologies as part of its
                                        Industry 4.0 Transformational Journey. We are pleased to partner with Atyaf
                                        eSolutions who are widely experienced in AR and VR technology implementation in
                                        the Region and accelerate our objectives under Alba’s ESG Roadmap.
                                    </p>
                                    <h3>Alba’s CEO Ali Al Baqali</h3>

                                </div>
                            </ng-template>
                        </owl-carousel-o>
                    </div>
                </div>
            </section>