<div class="page-title-area">
    <img src="assets/images/solutionbkg.jpeg" style="height: 700px; width: 100%; position: relative; z-index: -1;">
    <div class="container" style="position: relative; margin-top: -35%;">
        <div class="page-title-content">
            <h2 style="font-size: 50px;">VR Solutions</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Solutions</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="home-service-area ptb-100">
    <div class="container">
        <div class="row">
            <section class="home-company-area">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="company-content" style="padding-right: 10%;">
                                <div class="company-tittle">
                                    <span>Virtual Reality (VR)</span>
                                    <h2>Assists</h2>
                                    <p><br>- VR Remote Assistance and Support: VR Remote Assistance and Support enables experts to provide remote guidance using virtual annotations, simplifying complex tasks and troubleshooting for users. Utilizing VR technology, experts can immerse themselves in the user's environment, offering real-time visual cues and step-by-step instructions. This innovative solution enhances efficiency, reduces downtime, and fosters a seamless support experience across various industries, such as manufacturing, healthcare, and technical support.
                                    </p>
                                </div>
                            </div>
                        </div>
            
                        <div class="col-lg-6 col-md-12">
                            <div class="company-img">
                                <img src="assets/images/manufacturing.jpg" alt="company" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</section>
<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/vr1.jpg" alt="company" />
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-left: 10%;">
                    <div class="company-tittle">
                        <span>SNIP-X</span>
                        <h2>Experimental Sales and Marketing</h2>
                        <p>
                            <br>- VR Gaming Experiences: Create captivating and immersive VR gaming experiences, offering users interactive and realistic gameplay in virtual worlds.
                            <br>- Virtual Tourism and Exploration: Develop VR experiences that allow users to virtually explore destinations, historical sites, or natural wonders, providing an immersive travel experience from anywhere.
                            <br>- Virtual Prototyping and Design Review: Utilize VR to create and review prototypes in a virtual space, enabling designers and engineers to visualize and refine designs before physical production.
                            <br>- VR Therapy and Rehabilitation: Design VR applications for healthcare professionals to provide therapy, pain management, and rehabilitation services.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-right: 10%;">
                    <div class="company-tittle">
                        <span>Virtual Reality (VR)</span>
                        <h2>Training </h2>
                        <p><br>- VR Training Simulations: Build realistic VR training simulations for aviation, healthcare, manufacturing, and other industries to enhance learning and skill development.
                            <br>- VR Experiences: Create immersive VR experiences for education, entertainment, and virtual travel to engage users in a fully virtual environment.
                            <br>- VR Collaboration: Develop VR collaboration platforms for remote teams to work together, share ideas, and visualize designs in a shared virtual space.
                            </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/vr2.jpg" alt="company" />
                </div>
            </div>
        </div>
    </div>
</section>