<div class="page-title-area">
    <img src="assets/images/platformbkg.jpeg" style="height: 700px; width: 100%; position: relative; z-index: -1;">
    <div class="container" style="position: relative; margin-top: -35%;">
        <div class="page-title-content">
            <h2 style="font-size: 50px;">News and Press Release</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">News and Press Release</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>


<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content">
                    <div class="company-tittle">
                        <span>SNIP-X</span>
                        <h2>Latest News</h2>
                        <p>
                            <br>- MG motor India and metadome.ai collaborate to unleash MG Studioz: A groundbreaking AR/VR experience centre in Chennai
                            <br>- Apple launches its own augmented reality headset at WWDC23
                            <br>- IIT-Madras to develop Extended Reality centre for SAIL
                            <br>- From Google to Microsoft, 5 times brands failed in VR/AR market. And what we can learn from them
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/mr-nps.jpg" alt="company" />
                </div>
            </div>
        </div>
    </div>
</section>
