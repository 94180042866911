<div class="page-title-area">
    <img src="assets/images/solutionbkg.jpeg" style="height: 700px; width: 100%; position: relative; z-index: -1;">
    <div class="container" style="position: relative; margin-top: -35%;">
        <div class="page-title-content">
            <h2 style="font-size: 50px;">AR Solutions</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Solutions</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="feature-area bg-color ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="contnet">
                    <div class="feature-tittle">
                        <span>Solutions</span>
                        <h2>Solutions provided by SNIP-X</h2>
                        <p>SNIP-X offers cutting-edge solutions in augmented reality, virtual reality, mixed reality, metaverse, and virtual showrooms, transforming industries with immersive experiences and innovative technology. Harness the power of SNIP-X to unlock new possibilities and enhance your business.</p>
                    </div>
                    <ul>
                        <li><i class="flaticon-correct"></i> Augmented Reality (AR)</li>
                        <li><i class="flaticon-correct"></i> Virtual Reality (VR)</li>
                        <li><i class="flaticon-correct"></i> Mixed Reality (MR)</li>
                        <li><i class="flaticon-correct"></i> Metaverse</li>
                        <li><i class="flaticon-correct"></i> Virtual Showroom</li>
                    </ul>
                    <a routerLink="/contact" class="box-btn">Lets Talk!</a>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="feature-img">
                    <img src="assets/images/feature-img.png" alt="feature" />
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home-service-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Smart Services</span>
            <h2>SNIP-X Provide All Kind of Tech Solutions</h2>
            <p> SNIP-X delivers state-of-the-art solutions in augmented reality, virtual reality, mixed reality, metaverse, and virtual showrooms, revolutionizing industries through immersive experiences and pioneering technology.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s1.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Augmented Reality (AR)</h3>
                        <p>Augmented Reality (AR) is a technology that overlays digital information, such as images, videos, and 3D models, onto the real world.</p>
                          
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s2.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Virtual Reality (VR)</h3>
                        <p>Virtual Reality (VR) is a technology that creates a fully immersive and interactive digital environment, simulating real-world experiences.</p>
                          
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s3.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Mixed Reality (MR)</h3>
                        <p>Mixed Reality (MR) combines elements of both AR and VR to create a seamless blend of the real and virtual worlds.</p>
                          
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s4.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Metaverse</h3>
                        <p>The Metaverse is a virtual universe that combines physical and digital realities, enabling users to interact, explore, and socialize in a shared virtual space.</p>
                          
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s5.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Virtual Showroom</h3>
                        <p>A virtual showroom is a digital platform that allows users to explore and interact with products and services in a virtual environment.</p>
                          
                    </div>
                </div>
            </div>

            

            <section class="home-company-area">
                <div class="container">
                    <div class="row align-items-center">
                        
            
                        <div class="col-lg-6 col-md-12">
                            <div class="company-img">
                                <img src="assets/images/ar2.jpg" alt="company" />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="company-content" style="padding-left: 10%;">
                                <div class="company-tittle">
                                    <span>Augmented Reality (AR)</span>
                                    <h2>Assists </h2>
                                    <p><br>- AR Maintenance and Repair: Develop AR applications for maintenance technicians to receive real-time instructions and guidance for complex repairs irrespective of their location.The professionals can connect with a co-worker on another site or with an expert working at the backend to find the ideal solution.
                                       <br>- STEP by STEP Assists:Our AR field service solutions ensure that your field service technicians can execute fixes without any mistakes. They can be guided with step-by-step visual cues that minimize mistakes, prevent damage, and also avoid any possible injury to the technician.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="home-company-area">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="company-content" style="padding-right: 10%;">
                                <div class="company-tittle">
                                    <span>Augmented Reality (AR)</span>
                                    <h2>Experimental Sales and Marketing</h2>
                                    <p><br>- AR Marketing Solutions: Develop and deploy interactive AR marketing programs for industries such as aviation, healthcare, manufacturing, and more.
                                        <br>- AR Visualization: Create immersive AR experiences for real estate, allowing users to virtually tour properties and visualize designs.
                                        <br>- AR Marketing Campaigns: Design AR marketing campaigns to engage customers with interactive digital content, product demonstrations, and virtual try-ons.
                                        <br>- AR Virtual events: Let your event attendees become active participants and not just mute spectators of your virtual events. Engage them with immersive experiences coupled with gamification, virtual environments, and audio experiences.</p>
                                </div>
                            </div>
                        </div>
            
                        <div class="col-lg-6 col-md-12">
                            <div class="company-img">
                                <img src="assets/images/ar.jpg" alt="company" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</section>
<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/ar1.jpg" alt="company" />
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-left: 10%;">
                    <div class="company-tittle">
                        <span>Augmented Reality (AR)</span>
                        <h2> Training </h2>
                        <p><br>-  Immersive Learning through AR:Innovative advancements in education and training are leveraging augmented reality (AR) to replace traditional physical textbooks and instruction manuals. By utilizing AR applications, learners can now experience concepts in a real-world scenario, bridging the gap between theory and practice. This transition eliminates the potential misinterpretation of written words and fosters a deeper understanding of complex concepts through visually-rich, interactive content.
                            <br>- Gamifying Education and Training with AR:To make the learning experience engaging and enjoyable, augmented reality offers a platform to introduce gamification into education and training. By segmenting courses into multiple levels, each complete with scores and challenges, AR applications turn learning into an interactive and rewarding journey. Gamification techniques motivate learners to actively participate, set goals, and overcome obstacles in their educational path. This approach not only enhances the overall learning experience but also encourages learners to remain motivated, resulting in improved retention and a deeper understanding of the subject matter.
                            </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>  
