<div class="page-title-area">
    <img src="assets/images/platformbkg.jpeg" style="height: 700px; width: 100%; position: relative; z-index: -1;">
    <div class="container" style="position: relative; margin-top: -35%;">
        <div class="page-title-content">
            <h2 style="font-size: 50px;">Careers</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Careers</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>


<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content">
                    <div class="company-tittle">
                        <span>Our Company</span>
                        <h2>Careers</h2>
                        <p>Join the SNIP-X team and embark on an exciting journey at the forefront of AR/VR/MR innovation. We offer a collaborative and dynamic work environment where creativity and expertise thrive. With a focus on personal and professional growth, we provide opportunities to shape the future of immersive technologies and make a meaningful impact. Explore our diverse range of career openings, internships, and development programs, and become part of a passionate team committed to transforming industries through cutting-edge solutions. Ignite your career with SNIP-X and unlock your full potential in the world of immersive technology.</p>
                        <div class="banner-btn">
                            <a routerLink="/contact" class="box-btn" style="width: 50%;margin-left: 25%;">Contact Us</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/career.jpeg" alt="company" />
                </div>
            </div>
        </div>
    </div>
</section>