<div class="page-title-area">
    <img src="assets/images/solutionbkg.jpeg" style="height: 700px; width: 100%; position: relative; z-index: -1;">
    <div class="container" style="position: relative; margin-top: -35%;">
        <div class="page-title-content">
            <h2 style="font-size: 50px;">Metaverse</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Solutions</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="home-service-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Smart Services</span>
            <h2>SNIP-X Provide All Kind of Tech Solutions</h2>
            <p> SNIP-X delivers state-of-the-art solutions in augmented reality, virtual reality, mixed reality, metaverse, and virtual showrooms, revolutionizing industries through immersive experiences and pioneering technology.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s1.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Augmented Reality (AR)</h3>
                        <p>Augmented Reality (AR) is a technology that overlays digital information, such as images, videos, and 3D models, onto the real world.</p>
                          
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s2.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Virtual Reality (VR)</h3>
                        <p>Virtual Reality (VR) is a technology that creates a fully immersive and interactive digital environment, simulating real-world experiences.</p>
                          
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s3.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Mixed Reality (MR)</h3>
                        <p>Mixed Reality (MR) combines elements of both AR and VR to create a seamless blend of the real and virtual worlds.</p>
                          
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s4.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Metaverse</h3>
                        <p>The Metaverse is a virtual universe that combines physical and digital realities, enabling users to interact, explore, and socialize in a shared virtual space.</p>
                          
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s5.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Virtual Showroom</h3>
                        <p>A virtual showroom is a digital platform that allows users to explore and interact with products and services in a virtual environment.</p>
                          
                    </div>
                </div>
            </div>

            

            
            <section class="home-company-area">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="company-content" style="padding-right: 10%;">
                                <div class="company-tittle">
                                    <span>Metaverse</span>
                                    <h2>Assists </h2>
                                    <p>
                                        <br>-Virtual Prototyping and Design: Engineers, architects, and product designers can create virtual prototypes and conduct simulations to test and refine their designs before physical production.
                                        <br>-Remote Site Inspections: Engineers could remotely inspect construction sites, manufacturing facilities, or other locations through virtual representations, saving time and travel costs while still getting an accurate understanding of the situation.
                                        <br>- Virtual Healthcare and Telemedicine: Provide virtual healthcare services within the Metaverse, offering telemedicine consultations, virtual therapy sessions, and immersive health education experiences.
                                        
                                    </p>
                                </div>
                            </div>
                        </div>
            
                        <div class="col-lg-6 col-md-12">
                            <div class="company-img">
                                <img src="assets/images/metaverse1.jpeg" alt="company" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</section>
<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/metaverse.jpg" alt="company" />
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-left: 10%;">
                    <div class="company-tittle">
                        <span>Metaverse</span>
                        <h2>Experimental Sales and Marketing</h2>
                        <p><br>- Virtual Commerce: Establish virtual marketplaces within the Metaverse, allowing users to buy, sell, and trade virtual goods, digital assets, and even real-world products using virtual currencies.
                            <br>- Virtual Real Estate: Develop and sell virtual real estate within the Metaverse, offering users the opportunity to own and customize virtual properties, which can serve as personal spaces or business hubs.
                            <br>- Virtual Art Galleries and Museums: Showcase and experience art, cultural exhibits, and historical artifacts within virtual art galleries and museums, enabling global access and interactive storytelling.
                            <br>- Virtual Tourism and Exploration: Enable virtual tourism experiences within the Metaverse, allowing users to explore and interact with virtual representations of real-world landmarks, historical sites, and natural wonders.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-right: 10%;">
                    <div class="company-tittle">
                        <span>Metaverse</span>
                        <h2> Training</h2>
                        <p><br>- Virtual Events and Conferences: Host virtual events, conferences, and trade shows in the Metaverse, providing an immersive and interactive platform for attendees to network, learn, and showcase products.
                            
                            <br>- Virtual Education and Training: Utilize the Metaverse as an immersive learning platform, offering virtual classrooms, training simulations, and educational experiences that enhance engagement and knowledge retention.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/metaverse2.jpg" alt="company" />
                </div>
            </div>
        </div>
    </div>
</section>
