<div class="page-title-area">
    <img src="assets/images/solutionbkg.jpeg" style="height: 700px; width: 100%; position: relative; z-index: -1;">
    <div class="container" style="position: relative; margin-top: -35%;">
        <div class="page-title-content">
            <h2 style="font-size: 50px;">MR Solutions</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Solutions</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="home-service-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Smart Services</span>
            <h2>SNIP-X Provide All Kind of Tech Solutions</h2>
            <p> SNIP-X delivers state-of-the-art solutions in augmented reality, virtual reality, mixed reality, metaverse, and virtual showrooms, revolutionizing industries through immersive experiences and pioneering technology.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s1.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Augmented Reality (AR)</h3>
                        <p>Augmented Reality (AR) is a technology that overlays digital information, such as images, videos, and 3D models, onto the real world.</p>
                          
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s2.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Virtual Reality (VR)</h3>
                        <p>Virtual Reality (VR) is a technology that creates a fully immersive and interactive digital environment, simulating real-world experiences.</p>
                          
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s3.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Mixed Reality (MR)</h3>
                        <p>Mixed Reality (MR) combines elements of both AR and VR to create a seamless blend of the real and virtual worlds.</p>
                          
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s4.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Metaverse</h3>
                        <p>The Metaverse is a virtual universe that combines physical and digital realities, enabling users to interact, explore, and socialize in a shared virtual space.</p>
                          
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s5.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Virtual Showroom</h3>
                        <p>A virtual showroom is a digital platform that allows users to explore and interact with products and services in a virtual environment.</p>
                          
                    </div>
                </div>
            </div>

            
            <section class="home-company-area">
                <div class="container">
                    <div class="row align-items-center">
                        
            
                        <div class="col-lg-6 col-md-12">
                            <div class="company-img">
                                <img src="assets/images/vr.jpg" alt="company" />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="company-content" style="padding-left: 10%;">
                                <div class="company-tittle">
                                    <span>Mixed Reality (MR)</span>
                                    <h2>Assists</h2>
                                    <p><br>- MR Industrial Solutions: Develop MR solutions for manufacturing, oil and gas, and other industries to provide workers with real-time information overlays and hands-free operation.
 
                                        <br>- MR Design and Prototyping: Use MR technology to visualize and modify 3D designs in real-time, allowing architects and engineers to collaborate effectively.
                                        <br>- MR Remote Assistance: Implement MR remote assistance platforms that enable experts to guide field technicians using live video and annotations for troubleshooting and repairs.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="home-company-area">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="company-content" style="padding-right: 10%;">
                                <div class="company-tittle">
                                    <span>Mixed Reality (MR)</span>
                                    <h2>Experimental Sales and Marketing</h2>
                                    <p>
                                        <br>- Interactive Product Showcases: Immerse customers in interactive Mixed Reality (MR) experiences, enabling them to engage with virtual product versions, access detailed information, and personalize their choices. These MR encounters bring products to life, allowing customers to explore features, functionalities, and customization options in an intuitive and immersive manner. With an array of virtual tools at their disposal, customers can make informed decisions, gain a comprehensive understanding of the products, and tailor them to their preferences effortlessly.
                                    </p>
                                </div>
                            </div>
                        </div>
            
                        <div class="col-lg-6 col-md-12">
                            <div class="company-img">
                                <img src="assets/images/mr1.jpg" alt="company" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="home-company-area">
                <div class="container">
                    <div class="row align-items-center">
                        
            
                        <div class="col-lg-6 col-md-12">
                            <div class="company-img">
                                <img src="assets/images/mr.jpg" alt="company" />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="company-content" style="padding-left: 10%;">
                                <div class="company-tittle">
                                    <span>Mixed Reality (MR)</span>
                                    <h2>Training</h2>
                                    <p><br>- MR Collaboration: Enable teams to collaborate in real-time through MR, allowing them to interact with shared 3D models and digital content as if they were physically present.
                                        <br>- MR Spatial Data Visualization: Utilize MR to visualize complex spatial data in a 3D environment, allowing trainees to explore and analyze data in a more intuitive and interactive manner
                                        <br>- Digital Twin Integration: Combine MR with digital twin technology to create real-time visualizations and monitoring of physical assets or processes, facilitating better insights and decision-making.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>