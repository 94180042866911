<div class="page-title-area">
    <img src="assets/images/aviationx.jpg" style="height: 700px; width: 100%; position: relative; z-index: -1;">
    <div class="container" style="position: relative; margin-top: -35%;">
        <div class="page-title-content">
            <h2 style="font-size: 50px;color: blue;">Aviation</h2>
            <ul>
                <li><a routerLink="/" style="color: black;">Home </a></li>
                <li class="active" style="color: black;">Aviation</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="technology-area ptb-100 bg-color">
    <div class="container">
        <div class="row align-items-center choose-c">
            <div class="col-lg-6">
                <div>
                    <img src="assets/images/aviation.jpg" style="height: 550px; width: 85%; border-radius: 8%;"
                        alt="choose" />

                    <!-- <div class="technology-video">
                        <button type="button" class="video-btn p-0 border-0" (click)="openPopup()">
                            <i class='bx bx-play' ></i>
                        </button>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-6">
                <div class="technology-content">
                    <h2>Overview:</h2>
                    <p>The aviation industry encompasses various activities related to air transportation, including
                        airlines, airports, aircraft manufacturing, and maintenance. It is a complex and highly
                        regulated industry that requires efficient operations, safety, and enhanced customer experience.
                        In recent years, the aviation industry has witnessed the rapid evolution of technology, and one
                        area that holds immense promise for its transformation is the integration of Augmented Reality
                        (AR), Virtual Reality (VR), and Mixed Reality (MR) solutions. These cutting-edge technologies
                        offer a wide array of applications, from enhancing pilot training and streamlining aircraft
                        maintenance to revolutionizing the passenger experience. By seamlessly blending the physical and
                        digital worlds, AR, VR, and MR have the potential to address various challenges faced by the
                        industry, leading to improved safety, increased operational efficiency, and ultimately, a more
                        enriched aviation experience for all stakeholders involved.</p>
                    <a routerLink="/contact" class="box-btn">Contact Us</a>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="home-case pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Industries</span>
            <h2>SOLUTIONS FOR AVIATION INDUSTRY</h2>
        </div>
        <div class="case">
            <ul class="nav-tabset">
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab1'}">
                    <span (click)="switchTab($event, 'tab1')">
                        Aviation
                    </span>
                </li>


            </ul>


        </div>

    </div>
</section>

<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-right: 10%;">
                    <div class="company-tittle">
                        <span>SNIP-X</span>
                        <h2>Aviation Academy</h2>
                        <h3>Aircraft Fundamentals, Supplement Theory
                        </h3>
                        <p>The integration of Augmented Reality (AR), Virtual Reality (VR), and Mixed Reality (MR)
                            solutions holds immense potential for transforming aviation academies' teaching approach,
                            particularly in the domains of Aircraft Fundamentals and Supplement Theory. Through these
                            advanced technologies, students can experience immersive and interactive learning, exploring
                            3D models of aircraft components, practicing flight maneuvers in realistic simulations, and
                            collaborating in shared virtual environments for group learning. <br>
                            <br>1. Aircraft Fundamentals
                            <br>2. Immersive Trainings
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/av1.jpg" alt="company" style="width: 100%;" />
                </div>
            </div>
        </div>
    </div>
</section>
<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/av2.jpg" alt="company" style="width: 100%;" />
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-left: 10%;">
                    <div class="company-tittle">
                        <span>SNIP-X</span>
                        <h2>Airports</h2>
                        <h3>Aircraft familiarization, Emergency Response Procedures
                        </h3>
                        <p>AR, VR, and MR facilities help airports revolutionize their operations and safety practices.
                            By integrating these immersive technologies, airports can offer comprehensive aircraft
                            familiarization to ground staff, cabin crews, and maintenance personnel. Through interactive
                            simulations and virtual tours, employees gain an in-depth understanding of different
                            aircraft models, enabling them to perform their duties more efficiently and deliver superior
                            passenger experiences. <br><br>1. Safety procedures for firefighters and Incident commanders
                            <br>2. Firetruck Operations
                            <br>3. Airport Emergency Service
                        </p>
                    </div>
                </div>
            </div>


        </div>
    </div>
</section>
<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-right: 10%;">
                    <div class="company-tittle">
                        <span>SNIP-X</span>
                        <h2>Airlines</h2>
                        <h3>Passenger experience, Marketing, Cabin Crew Training
                        </h3>
                        <p>AR, VR, and MR solutions offer airlines an array of possibilities to enhance passenger
                            experiences, revolutionize marketing strategies, and elevate cabin crew training. Virtual
                            tours of destinations using VR can stimulate pre-flight excitement, while AR overlays on
                            inflight entertainment screens create interactive content. Virtual shopping experiences
                            using AR markers engage passengers during flights. In marketing, AR and MR elements in ads
                            or boarding passes provide interactive experiences, and VR campaigns offer a taste of the
                            airline's premium services. For cabin crew training, VR enables realistic emergency
                            simulations and aircraft familiarization, while AR aids in customer service scenarios. <br>
                            <br>1. Aircraft Familiarization
                            <br>2. Cabin Crew Training
                            <br>3. Marketing Cabin Experience
                            <br>4. In-Flight Entertainment
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/av3.jpg" alt="company" style="width: 100%;" />
                </div>
            </div>
        </div>
    </div>
</section>
<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/av4.jpg" alt="company" style="width: 100%;" />
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-left: 10%;">
                    <div class="company-tittle">
                        <span>SNIP-X</span>
                        <h2>MRO</h2>
                        <h3>Aircraft Maintenance Training @ Engine, Airframe, Avionics
                        </h3>
                        <p>The Aircraft Maintenance Training in the Maintenance, Repair, and Overhaul (MRO) sector,
                            covering Engine, Airframe, and Avionics disciplines, stands to be revolutionized through the
                            integration of Augmented Reality (AR), Virtual Reality (VR), and Mixed Reality (MR)
                            solutions. These cutting-edge technologies enable immersive and interactive training for
                            engine technicians, with virtual simulations facilitating risk-free disassembly, diagnosis,
                            and reassembly. VR and MR enhance airframe maintenance by visualizing the entire aircraft
                            structure, optimizing inspections, repairs, and assembly. <br>
                            <br>- VR Aircraft
                            Maintenance Training
                            <br>1. Aircrafts
                            <br>2. Engines

                        </p>
                    </div>
                </div>
            </div>


        </div>
    </div>
</section>
<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-right: 10%;">
                    <div class="company-tittle">
                        <span>SNIP-X</span>
                        <h2>Ground Handling</h2>
                        <h3>Ground Operations, Baggage Handling, Logistics, Catering
                        </h3>
                        <p>The integration of AR, VR, and MR solutions offers substantial potential to revolutionize
                            ground handling in aviation. These advanced technologies streamline ground operations,
                            ensuring efficiency and safety. Baggage handling benefits from AR's real-time information on
                            luggage, reducing errors and delays, while MR guides handlers through loading procedures.
                            Logistics optimize with VR-created virtual layouts and improved inventory management.
                            Catering staff receive immersive training through AR and VR, leading to more efficient
                            onboard service. <br>
                            <br>1. Baggage handling
                            <br>2. Ground Handling
                            <br>3. Passenger Services
                            <br>4. Security Services

                        </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/av5.jpg" alt="company" style="width: 100%;" />
                </div>
            </div>
        </div>
    </div>
</section>

<div class="section-title">
    <span></span>
    <h2>OFF THE SHELF PRODUCTS</h2>
</div>

<div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-inner">
        <div class="carousel-item active">
            <img src="assets/images/is1.png" class="d-block w-100" alt="Slide 1">
        </div>
        <div class="carousel-item">
            <img src="assets/images/is2.png" class="d-block w-100" alt="Slide 2">
        </div>
        <div class="carousel-item">
            <img src="assets/images/is3.png" class="d-block w-100" alt="Slide 3">
        </div>

    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
    </button>
</div>

<section class="technology-area ptb-100 bg-color">
    <div class="container">
        <div class="col-lg-12">
            <div class="technology-content" style="text-align: center;">
                <h2>VR TRAINING MODULES:</h2>
                <p>In these VR training modules, the user gets an immersive & hands-on experience with self-guided steps
                    for performing the operations on ground handling equipment. Since the training modules replicate the
                    actual SOP of ground handling operations, the user obtains the same outputs and experience of
                    performing the SOP/ procedure as it would be in a real-life scenario. This VR training enables to
                    user to learn & train by doing the operation themselves.
                </p>
                <div class="row">
                    <div class="col-lg-4 col-sm-6">
                        <div class="single-case">
                            <div class="case-img">
                                <img src="assets/images/av6.jpg"
                                    style="height: 13rem !important; width: 100% !important;" alt="case" />
                            </div>

                            <div class="content" style="text-align: center;">
                                <h4>Air Starter Unit

                                </h4>


                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <div class="single-case">
                            <div class="case-img">
                                <img src="assets/images/av7.jpg"
                                    style="height: 13rem !important; width: 100% !important;" alt="case" />
                            </div>

                            <div class="content" style="text-align: center;">
                                <h4>Marshalling

                                </h4>


                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <div class="single-case">
                            <div class="case-img">
                                <img src="assets/images/av8.jpg"
                                    style="height: 13rem !important; width: 100% !important;" alt="case" />
                            </div>

                            <div class="content" style="text-align: center;">
                                <h4>Pushback Tractor


                                </h4>


                            </div>
                        </div>
                    </div>
                </div>
                <a routerLink="/contact" class="box-btn">Contact Us</a>
            </div>

        </div>
    </div>
</section>
<div class="section-title">
    <span></span>
    <h2>BENEFITS & VALUE ADDITION</h2>
</div>
<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-right: 10%;">
                    <div class="company-tittle">
                        <h2>XR Case Study: Gulf Aviation Academy
                        </h2>
                        <p>GAA uses Atyaf’s SNIP-X platform for Virtual & Augmented Reality based ground handling
                            modules to facilitate education and training in its Aviation School.
                            <br>GAA & Atyaf is also developing a range of training applications for use across various
                            Virtual Reality systems – including Desktop based Virtual Reality systems as well as mobile
                            devices such as tablets and smartphones.
                            <br>VR Modules: Marshalling, Pushback Tractor, Ground Power Unit, Headset Operation, Air
                            Starter Unit, Air Condition Unit, Lavatory & Potable Water Unit, High Loader, Catering
                            Truck, Passenger Steps and more.
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/av9.png" alt="company" />
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home-service-area ptb-100">
    <div class="container">


        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/Picture1.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Faster Training
                        </h3>


                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/Picture2.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Reduce errors
                        </h3>


                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/Picture3.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Reduce Training Cost
                        </h3>


                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/Picture4.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Safe & Controlled Environment</h3>


                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/Picture5.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Improved Compliance
                        </h3>


                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/Picture6.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Accurate KPI
                        </h3>


                    </div>
                </div>
            </div>


            <section class="home-contact-area home-2-contact pb-100">
                <div class="container">
                    <div class="section-title">
                        <span>Contact Us</span>
                        <h2>Let Us Know About Your Project Idea!</h2>
                        <p>It is a long established fact that a reader will be distracted by the rea dable content of a
                            page when looking at its layout. The point of using Lorem Ipsum is that it has a more.</p>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="content">
                                <form id="contactForm">
                                    <div class="row">
                                        <div class="col-lg-12 col-sm-12">
                                            <div class="form-group">
                                                <input type="text" name="name" id="name" class="form-control" required
                                                    placeholder="Your Name" />
                                            </div>
                                        </div>

                                        <div class="col-lg-12 col-sm-12">
                                            <div class="form-group">
                                                <input type="email" name="email" id="email" class="form-control"
                                                    required placeholder="Your Email" />
                                            </div>
                                        </div>

                                        <div class="col-lg-12 col-sm-12">
                                            <div class="form-group">
                                                <input type="text" name="phone_number" id="phone_number" required
                                                    class="form-control" placeholder="Your Phone" />
                                            </div>
                                        </div>

                                        <div class="col-lg-12 col-sm-12">
                                            <div class="form-group">
                                                <input type="text" name="msg_subject" id="msg_subject"
                                                    class="form-control" required placeholder="Subject" />
                                            </div>
                                        </div>

                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-group">
                                                <textarea name="message" class="form-control" id="message" cols="30"
                                                    rows="5" required placeholder="Your Message"></textarea>
                                            </div>
                                        </div>

                                        <div class="col-lg-12 col-md-12">
                                            <button type="submit" class="default-btn page-btn box-btn">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="contact-img contact-img-2">
                                <img src="assets/images/contact-img2.jpg" alt="contact" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>