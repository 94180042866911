<div class="page-title-area">
    <img src="assets/images/platformbkg.jpeg" style="height: 700px; width: 100%; position: relative; z-index: -1;">
    <div class="container" style="position: relative; margin-top: -35%;">
        <div class="page-title-content">
            <h2 style="font-size: 50px;">TMS PORTAL</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">TMS PORTAL</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="technology-area ptb-100 bg-color">
    <div class="container">
        <div class="row align-items-center choose-c">
            <div class="col-lg-6">
                <div>
                    <img src="assets/images/tms-portal.png"  style="height: 550px;background-color: grey; border-radius: 8%;" alt="choose" />

                   
                </div>
            </div>

            <div class="col-lg-6">
                <div class="technology-content">
                    <h2>Overview</h2>
                    <p>
                        Welcome to our XR session scheduling and data analytics platform, the ultimate solution for streamlining your XR training and assessment sessions. Our platform allows you to effortlessly administer and manage all your extended reality (XR) sessions from a centralized web-based interface, powered by the robust capabilities of Microsoft Azure. Say goodbye to the complexities of coordinating XR sessions across various locations and devices. With our platform, you can efficiently schedule, monitor, and analyze XR sessions, enabling data-driven insights to enhance the effectiveness of your training programs. Unlock the true potential of XR technology with our user-friendly and innovative platform.</p>
                    <a routerLink="/contact" class="box-btn">Contact Us</a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home-service-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>SNIP-X</span>
            <h2>Store and Export data from One central location for all XR content and administration</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/p1.jpg" alt="service" style="border-radius: 100%;width: 50%;"/>
                    </div>

                    <div class="service-content">
                        <p>A scalable framework and modular templates for rapid multi-user content creation and collaboration.</p>
                          
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/p2.png" alt="service" style="border-radius: 100%;width: 40%"/>
                    </div>

                    <div class="service-content">
                        
                        <p>
                            The platform features dedicated dashboards for trainers and trainees, optimizing their XR session management experience.</p>
                          
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/p3.jpg" alt="service" style="border-radius: 100%;width: 40%"/>
                    </div>

                    <div class="service-content">
                        
                        <p>
                            A versatile platform allowing trainers to seamlessly participate in multiple live sessions, accessible through VR and desktop viewers.</p>
                          
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/p4.png" alt="service" style="border-radius: 100%;width: 30%"/>
                    </div>

                    <div class="service-content">
                        
                        <p>3D Screen recording and playback.</p>
                          
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/p5.png" alt="service" style="border-radius: 100%;width: 30%"/>
                    </div>

                    <div class="service-content">
                        
                        <p>Real time skill assessment and data analysis.</p>
                          
                    </div>
                </div>
            </div>




<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/tms3.png" alt="company" />
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-left: 10%;">
                    <div class="company-tittle">
                        <span>  SNIP-X</span>
                        <h2>Trainee Dashboard</h2>
                        <h3>Enhanced engagement and valuable data insights</h3>
                        <p>
                            The trainee portal offers students a convenient overview of their scheduled multi-user sessions, including group guided learning, one-on-one practice with mentors, and assessments. The schedule can be displayed in either a table or calendar view. Moreover, the portal features a performance dashboard where students can access their latest results and data analytics, helping them track their progress effectively.
                        </p>
                        <h3>Advantages:</h3>
                        <p>
                            1.Conveniently access your training schedule anytime, from web or mobile devices.
                            <br>2.Review assigned scenarios and related information effortlessly.
                            <br>3.Stay up-to-date with your latest training scores and results.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-right: 10%;">
                    <div class="company-tittle">
                        <span>SNIP-X</span>
                        <h2>Trainer Portal</h2>
                        <h3>
                            Optimize enterprise training deployment with advanced capabilities</h3>
                        <p>
                            The trainer portal serves as a comprehensive platform for instructors and administrators to efficiently oversee class management and schedule multi-user sessions. Through this interface, they can effortlessly handle various aspects of their educational programs, such as creating and modifying classes, organizing group sessions, and managing participants' attendance and progress. The portal streamlines administrative tasks, ensuring a seamless and effective learning experience for all involved parties.</p>
                        
                        <h3>Trainer Portal features:</h3>
                        <p>
                            1.Create and manage classes and users, facilitating multiuser sessions for group training or assessments.
                            <br>2.Easily access scheduled sessions through calendar or table views.
                            <br>3.Generate secure one-time passcodes for session access and ensure data privacy.
                            <br>4.Access detailed real-time data analytics and reporting for performance evaluation.
                            <br>5.Initiate trainer VR or desktop viewing sessions for immersive learning experiences.
                            <br>6.Record sessions for future replay and review purposes.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/tms4.png" alt="company" />
                </div>
            </div>
        </div>
    </div>
</section>

<div class="section-title">
    <span>SNIP-X</span>
    <h2>BENIFITS AND VALUE ADDITION</h2>
</div>

<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/tms7.png" alt="company" />
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-left: 10%;">
                    <div class="company-tittle">
                        <span>  SNIP-X</span>
                        <h2>
                            Instantaneous monitoring and evaluation in real-time</h2>
                        
                        <p>

                            With our cutting-edge platform, trainers have the power of real-time tracking and assessment at their fingertips. Our portal allows them to seamlessly monitor all ongoing live sessions, enabling a comprehensive view of participants' progress. Additionally, our user-friendly desktop application empowers trainers to actively engage and participate in the sessions whenever needed. This dynamic approach ensures a seamless and efficient training experience, fostering enhanced communication and prompt intervention. With our innovative tools, trainers can optimize their training strategies and provide unparalleled support, leading to improved learning outcomes for all participants.
                                                </p>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-right: 10%;">
                    <div class="company-tittle">
                        <span>SNIP-X</span>
                        <h2>Empowering users with actionable insights for enhanced XR session management and data-driven decision-making

                        </h2>
                        
                        <p>

                            Portal's integrated analytics provide real-time user progress tracking and effortless report generation. These actionable insights empower administrators and stakeholders with up-to-date data, enabling informed decision-making and timely interventions. With seamless access to valuable metrics, the platform ensures a data-driven approach to optimize user experiences and enhance overall performance.
                                                </p>
                        
                       
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/tms6.png" alt="company" />
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/tms5.png" alt="company" />
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-left: 10%;">
                    <div class="company-tittle">
                        <span>  SNIP-X</span>
                        <h2>INDUSTRY 4.0 Solutions On any device</h2>
                        
                        <p>
                            Our platform boasts a streamlined experience with a singular launcher app. Effortlessly versatile, this app eliminates the need for multiple installations, regardless of the training scenarios available. Once installed, it seamlessly retrieves specific user content from the cloud, simplifying the process and ensuring a hassle-free journey. Emphasizing efficiency and convenience, our platform's single launcher app revolutionizes the way users access and interact with the vast array of training scenarios at their fingertips. Welcome to a unified, content-rich experience like no other.
                                                </p>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
