<footer class="footer-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="content">
                    <div class="logo">
                        <a routerLink="/"><img src="assets/images/logo/snip-x logo.png" alt="logo" style="height: 15%; width: 30%;" /></a>
                    </div>
                    <p>Join the Snip X team and embark on an exciting journey at the forefront of AR/VR/MR innovation.</p>

                    <div class="subscribe">
                        <h4>Join Newsletter</h4>
                        <form class="newsletter-form">
                            <input type="email" id="emails" class="form-control" placeholder="Your Email" name="EMAIL" required autocomplete="off">
                            <button class="box-btn" type="submit">Subscribe</button>
                        </form>
                    </div>

                    <ul class="social">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook' ></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter' ></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-instagram' ></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest' ></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="content ml-15">
                    <h3>Our Service</h3>

                    <ul class="footer-list">
                        <li><a routerLink="/aviation">Aviation</a></li>
                        <li><a routerLink="/education">Education</a></li>
                        <li><a routerLink="/healthcare">Healthcare</a></li>
                        <li><a routerLink="/manufacturing">Manufacturing</a></li>
                        <li><a routerLink="/oil&gas">Oil & Gas</a></li>
                        <li><a routerLink="/real-estate">Real Estate</a></li>

                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-md-6">
                <div class="content">
                    <h3>Quick Links</h3>

                    <ul class="footer-list">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/about">About</a></li>
                        <li><a routerLink="/contact">Contact</a></li>
                        <li><a routerLink="/about-platform">SNIP-X Platform</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="content contacts">
                    <h3 class="ml-40">Contact</h3>

                    <ul class="footer-list foot-social">
                        <!-- <li><a href="tel:+97336092002"><i class="bx bx-mobile-alt"></i>+97336092002</a></li> -->
                        <li><a href="tel:+97317877900"><i class="bx bx-phone"></i>+97317877900</a></li>
                        <!-- <li><a href="mailto:janil@atyafesolutions.com"><i class="bx bxs-envelope"></i>janil@atyafesolutions.com</a></li> -->
                        <li><a href="mailto:snipx@atyafesolutions.com"><i class="bx bxs-envelope"></i>snipx@atyafesolutions.com</a></li>
                        <li><i class="bx bxs-map"></i>Building 453, Office No. 23, Rd No 711, Muharraq, Bahrain</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    
</footer>

<div class="go-top">
    <i class='bx bx-chevrons-up'></i>
    <i class='bx bx-chevrons-up'></i>
</div>