<div class="page-title-area">
    <img src="assets/images/platformbkg.jpeg" style="height: 700px; width: 100%; position: relative; z-index: -1;">
    <div class="container" style="position: relative; margin-top: -35%;">
        <div class="page-title-content">
            <h2 style="font-size: 50px;">SNIP-X Products</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">SNIP-X Products</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="technology-area ptb-100 bg-color">
    <div class="container">
        <div class="row align-items-center choose-c">
            <div class="col-lg-6">
                <div>
                    <img src="assets/images/snipa.jpeg"  style="width: 80%; border-radius: 8%;" alt="choose" />

                    <!-- <div class="technology-video">
                        <button type="button" class="video-btn p-0 border-0" (click)="openPopup()">
                            <i class='bx bx-play' ></i>
                        </button>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-6">
                <div class="technology-content">
                    <h2>SNIP-X Assist</h2>
                    <p>SNIP-X Assist can help to increase productivity by making it easier for people to complete tasks more quickly and accurately. This can be especially helpful in complex or time-sensitive tasks.
                    </p>
                    <a routerLink="/contact" class="box-btn">Contact Us</a>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                
                    <img src="assets/images/plat2.jpeg" alt="company" style="width: 100%;" />
                
            </div>

            <div class="col-lg-6 col-md-12">
                
                    <img src="assets/images/plat3.jpeg" alt="company" style="width: 100%;" />
               
            </div>
        </div>
    </div>
</section>
<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/ig.jpeg" alt="company" />
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-left: 10%;">
                    <div class="company-tittle">
                        <span>  SNIP-X</span>
                        <h2>Inspection Guide</h2>
                        <p>The Inspection Guide feature in SNIP-X Assist is designed to streamline and standardize the inspection process within virtual environments. It provides users with a comprehensive checklist or workflow that outlines the specific steps and requirements for conducting inspections. The guide prompts users to assess different aspects such as safety, quality, functionality, or compliance based on the nature of the inspection. By following the Inspection Guide, users can ensure that inspections are thorough, consistent, and meet the necessary criteria.

                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-right: 10%;">
                    <div class="company-tittle">
                        <span>  SNIP-X</span>
                        <h2>Breakdown Assists</h2>
                        <p>Breakdown Assists in SNIP-X Assist play a crucial role in helping users effectively handle equipment or system failures within virtual environments. When faced with a breakdown scenario, users can rely on Breakdown Assists for immediate guidance and support. The feature offers a range of resources, including visual aids, interactive tutorials, or step-by-step instructions, to help users diagnose the issue and take appropriate troubleshooting actions. By leveraging Breakdown Assists, users can efficiently address breakdowns, minimize downtime, and gain valuable experience in resolving real-world equipment failures.'</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/ba.jpeg" alt="company" />
                </div>
            </div>
        </div>
    </div>
</section>
<section class="technology-area ptb-100 bg-color">
    <div class="container">
        <div class="row align-items-center choose-c">
            <div class="col-lg-6">
                <div>
                    <img src="assets/images/snipt.jpeg"  style="width: 80%; border-radius: 8%;" alt="choose" />

                    <!-- <div class="technology-video">
                        <button type="button" class="video-btn p-0 border-0" (click)="openPopup()">
                            <i class='bx bx-play' ></i>
                        </button>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-6">
                <div class="technology-content">
                    <h2>SNIP-X Train</h2>
                    <p>SNIP-X Train can help learners to better understand complex concepts quickly and efficiently. By placing learners in a simulated environment, XR training can make it easier for them to learn new skills faster and apply it better in real-world situations.
                    </p>
                    <a routerLink="/contact" class="box-btn">Contact Us</a>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                
                    <img src="assets/images/plat4.jpeg" alt="company" style="width: 100%;" />
                
            </div>

            <div class="col-lg-6 col-md-12">
                
                    <img src="assets/images/plat2.jpeg" alt="company" style="width: 100%;" />
               
            </div>
        </div>
    </div>
</section>
<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/ct.jpeg" alt="company" />
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-left: 10%;">
                    <div class="company-tittle">
                        <span>  SNIP-X</span>
                        <h2>Classroom Training</h2>
                        <p>SNIP-X's Classroom Training feature provides a virtual environment that simulates a traditional classroom setting. It enables trainers to deliver interactive and engaging training sessions to a group of learners. Users can join scheduled classes, participate in discussions, and collaborate with trainers and fellow learners in real-time. Classroom Training in SNIP-X offers a range of tools such as whiteboards, presentation slides, and interactive activities to enhance the learning experience. This mode allows for structured instruction, knowledge sharing, and the opportunity for learners to ask questions and receive immediate feedback.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-right: 10%;">
                    <div class="company-tittle">
                        <span>  SNIP-X</span>
                        <h2>On-job Training</h2>
                        <p>SNIP-X's On-the-Job Training feature focuses on immersive learning experiences that simulate real-world work scenarios. It allows learners to engage in practical training exercises and simulations within virtual environments. Users can perform tasks, operate equipment, and handle work-related challenges in a safe and controlled setting. On-the-Job Training in SNIP-X provides learners with hands-on experience, allowing them to apply their skills, make decisions, and develop critical thinking abilities. This mode is particularly useful for vocational or technical training, as it bridges the gap between theoretical knowledge and practical application.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/ojt.jpeg" alt="company" />
                </div>
            </div>
        </div>
    </div>
</section>

