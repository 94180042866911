<div class="page-title-area">
    <img src="assets/images/healthcarex.jpg" style="height: 700px; width: 100%; position: relative; z-index: -1;">
    <div class="container" style="position: relative; margin-top: -35%;">
        <div class="page-title-content">
            <h2 style="font-size: 50px;color: #000;">Healthcare</h2>
            <ul>
                <li><a routerLink="/" style="color: black;">Home   </a></li>
                <li class="active" style="color: black;">Healthcare</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>



<section class="technology-area ptb-100 bg-color">
    <div class="container">
        <div class="row align-items-center choose-c">
            <div class="col-lg-6">
                <div>
                    <img src="assets/images/hth1.jpg"  style="height: 550px; width: 85%; border-radius: 8%;" alt="choose" />

                    
                </div>
            </div>

            <div class="col-lg-6">
                <div class="technology-content">
                    <h2>Overview:</h2>
                    <p>The integration of AR, VR, and MR technologies into the healthcare industry brings transformative effects. These immersive technologies are poised to revolutionize medical training by providing more realistic and interactive learning experiences for aspiring healthcare professionals. They also revolutionize patient care through improved diagnostic capabilities, allowing for more accurate and precise assessments. Additionally, AR, VR, and MR solutions facilitate remote healthcare, enabling healthcare providers to reach patients in remote areas and offer virtual consultations. The implementation of these technologies not only increases efficiency and reduces costs but also results in enhanced patient engagement and satisfaction. As these technologies continue to advance, they reshape the healthcare landscape by providing innovative and effective solutions for various medical challenges.</p>
                    <a routerLink="/contact" class="box-btn">Contact Us</a>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="home-case pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h1 style="text-align: center;font-size: 500%;margin-bottom: 5%;">Healthcare Industry</h1>
            <h2>PROBLEMS</h2>
            <h3>The healthcare industry is facing multiple challenges.</h3>
        </div>
        <div class="case">
            <ul class="nav-tabset">
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab1'}">
                    <span (click)="switchTab($event, 'tab1')">
                        Healthcare
                    </span>
                </li>
                
                
            </ul>
            
           
        </div>
        
    </div>
</section>

<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-right: 10%;">
                    <div class="company-tittle">
                        <span>SNIP-X</span>
                        <h2>Healthcare Problem</h2>
                        <p>1.Cost – The need to access a physical equipment/ machine to train-on increases the cost of training significantly. There is a need to minimize the time and resources spent on training, while also maintaining high quality.
                            <br>2.Complexity – Increasing complexity of medical technology and consequences for training. 
                            <br>3.Risk - Management cannot effectively train for the wide variety of critical situations a trainee might face. Trainees are unable to realistically simulate all the issues they may face during Medical operations and procedures.
                            
                            </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/hth2.jpg" alt="company" style="width: 100%;" />
                </div>
            </div>
        </div>
    </div>
</section>
<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/hth3.jpg" alt="company" style="width: 100%;"/>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-left: 10%;">
                    <div class="company-tittle">
                        <span>SNIP-X</span>
                        <h2>Education Problem</h2>
                    
                        <p>4.Quality - Traditional training and education practices does not give hands-on experience, nor does it support critical thinking, problem solving, communication and grit that is crucial for learning complicated procedures. 
                            <br>5.Difficult To Assess - Traditional evaluation methods, which emphasize classroom performance, are often insufficient in evaluating long term field capabilities.
                            
                            </p>
                    </div>
                </div>
            </div>

            
        </div>
    </div>
</section>
<section class="home-company-area">
    <div class="container">
        <div class="section-title">
            <span>Healthcare</span>
            <h2>OPPORTUNITIES</h2>
            
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-right: 10%;">
                    <div class="company-tittle">
                        <span>SNIP-X</span>
                        <h2>MEDICAL EDUCATION</h2>
                       
                        <p>
                            AR, VR, and MR are revolutionizing medical education by offering experiential and interactive learning opportunities for students and healthcare professionals. Virtual reality, in particular, allows learners to engage with 3D medical models, providing in-depth exploration and understanding that physical models can't replicate. These immersive technologies enhance medical training by simulating realistic scenarios, enabling practice without risk to patients. Learners can dissect virtual anatomy, perform virtual surgeries, and participate in lifelike simulations, fostering a deeper understanding of complex medical concepts. This transformative approach to education equips future medical practitioners with practical skills and knowledge, ultimately improving patient care and medical outcomes.
                            </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/hth4.jpg" alt="company" style="width: 100%;"/>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/hth5.jpg" alt="company" style="width: 100%;"/>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-left: 10%;">
                    <div class="company-tittle">
                        <span>SNIP-X</span>
                        <h2>MEDICAL PRACTICE
                        </h2>
                        
                        <p>AR, VR, and MR are revolutionizing medical practice through experiential virtual reality training. These technologies offer immersive simulations, allowing healthcare professionals to practice performing complex procedures and operations in virtual environments. Trainees can gain hands-on experience without the risks associated with real-life scenarios. This training approach enhances skill development, improves decision-making, and boosts confidence among medical practitioners. Moreover, AR enhances surgical navigation by overlaying essential information onto a surgeon's field of view, while VR creates realistic patient simulations for diagnostics. As these technologies advance, their potential to revolutionize medical training and enhance patient care continues to grow exponentially.
                    </div>
                </div>
            </div>

            
        </div>
    </div>
</section>
<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content" style="padding-right: 10%;">
                    <div class="company-tittle">
                        <span>SNIP-X</span>
                        <h2>PATIENT EDUCATION & THERAPY
                        </h2>
                        
                        <p>AR, VR, and MR technologies significantly enhance patient education and therapy. These immersive technologies enable patients to better understand their health conditions and treatment plans through realistic, interactive visualizations. In therapy, VR simulations provide a safe, controlled environment for physical and psychological rehabilitation. Patients can participate in gamified experiences that make therapy sessions more engaging, motivating them to continue their treatment. Alternatively, they can be immersed in soothing environments with audio stimulation for relaxation and stress reduction. Thus, AR, VR, and MR technologies play a crucial role in improving patient education, compliance, and therapeutic outcomes.
                            </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/hth6.jpg" alt="company" style="width: 100%;" />
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <div class="section-title">
    <span></span>
    <h2>XR CASE STUDY</h2>
</div> -->

<!-- <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img src="assets/images/hth7.jpg" class="d-block w-100" alt="Slide 1">
      </div>
      <div class="carousel-item">
        <img src="assets/images/hth8.jpg" class="d-block w-100" alt="Slide 2">
      </div>
      <div class="carousel-item">
        <img src="assets/images/hth9.png" class="d-block w-100" alt="Slide 3">
      </div>
      
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div> -->

<section class="technology-area ptb-100 bg-color">
    <div class="container">
        <div class="col-lg-12">
            <div class="technology-content" style="text-align: center;">
                    <h2>XR CASE STUDY</h2>
                    
                <div class="row">
                    <div class="col-lg-4 col-sm-6">
                        <div class="single-case">
                            <div class="case-img">
                                  <img src="assets/images/hth4.jpg" style="height: 13rem !important; width: 100% !important;"  alt="case"/>  
                            </div>
        
                            <div class="content" style="text-align: center;">
                                <h4>How VR for medical education works:</h4>  
                                <p>Any virtual learning session can be held in groups or individually and guided either by a real tutor, who is also connected to the VR app or by a virtual preprogrammed tutor controlled by the AI algorithms.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <div class="single-case">
                            <div class="case-img">
                                <img src="assets/images/hth5.jpg" style="height: 13rem !important; width: 100% !important;"  alt="case"/>  
                            </div>
        
                            <div class="content" style="text-align: center;">
                                <h4>How VR for surgery works:                                </h4>  
                                <p>With the help of haptic controllers, a surgeon performs a virtual surgery in a virtual operating room. From start to finish, software guides a user through the necessary steps, letting them remember the right sequence of actions. The VR app can also be used for explaining the surgery process to patients.
                                </p>
                                       
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <div class="single-case">
                            <div class="case-img">
                                <img src="assets/images/hth6.jpg" style="height: 13rem !important; width: 100% !important;"  alt="case"/>  
                            </div>
        
                            <div class="content" style="text-align: center;">
                                <h4>How VR for physical therapy works:</h4>  
                                <p>All exercises can be supervised by either an AI-based virtual instructor or a real instructor, who joins the VR sessions remotely. A comprehensive analytics system continuously monitors a patient’s condition and ensures that the exercises do not harm a patient's health in any way.
                                </p>
                                       
                            </div>
                        </div>
                    </div>
                </div>
                    
            </div>
            <!--22222222222222222222222222222222-->
            <div class="technology-content" style="text-align: center;margin-top: 5%;">
                <h3>USE CASES</h3>
                
            <div class="row">
                <div class="col-lg-4 col-sm-6">
                    <div class="single-case">
                       
    
                        <div class="content" style="text-align: center">
                            <p>-Heart learning
                               <br> -Medical social skills learning
                               <br>-Medical training
                               <br>-Medical equipment use learning
                               
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <div class="single-case">
                        
    
                        <div class="content" style="text-align: center;">
                            <p>-Surgical training
                                <br>-Pre-surgical planning
                                <br>-Medical device marketing
                                
                            </p>
                                   
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <div class="single-case">
                        
                        <div class="content" style="text-align: center;">
                            <p>-Sports injuries.
                                <br>-Child-care/Geriatrics.
                                <br>-Adaptation to assistive <br>devices or prosthetics.
                                
                            </p>
                                   
                        </div>
                    </div>
                </div>
            </div>
                <a routerLink="/contact" class="box-btn">Contact Us</a> 
        </div>
        
        </div>
    </div>
</section>
<div class="section-title">
    <span></span>
    <h2 style="margin-top: 15%;">BENEFITS & VALUE ADDITION</h2>
</div>


<section class="home-service-area ptb-100">
    <div class="container">
        

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/Picture1.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Faster Training
                        </h3>
                        
                          
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/Picture2.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Reduce errors
                        </h3>
                        
                          
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/Picture3.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Reduce Training Cost
                        </h3>
                        
                          
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/Picture4.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Safe & Controlled Environment</h3>
                        
                          
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/Picture5.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Improved Compliance
                        </h3>
                        
                          
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/Picture6.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Accurate KPI
                        </h3>
                        
                          
                    </div>
                </div>
            </div>


            <section class="home-contact-area home-2-contact pb-100">
                <div class="container">
                    <div class="section-title">
                        <span>Contact Us</span>
                        <h2>Let Us Know About Your Project Idea!</h2>
                        <p>It is a long established fact that a reader will be distracted by the rea dable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more.</p>
                    </div>
            
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="content">
                                <form id="contactForm">
                                    <div class="row">
                                        <div class="col-lg-12 col-sm-12">
                                            <div class="form-group">
                                                <input type="text" name="name" id="name" class="form-control" required placeholder="Your Name" />
                                            </div>
                                        </div>
            
                                        <div class="col-lg-12 col-sm-12">
                                            <div class="form-group">
                                                <input type="email" name="email" id="email" class="form-control" required placeholder="Your Email" />
                                            </div>
                                        </div>
            
                                        <div class="col-lg-12 col-sm-12">
                                            <div class="form-group">
                                                <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Your Phone" />
                                            </div>
                                        </div>
            
                                        <div class="col-lg-12 col-sm-12">
                                            <div class="form-group">
                                                <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Subject" />
                                            </div>
                                        </div>
            
                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-group">
                                                <textarea name="message" class="form-control" id="message" cols="30" rows="5" required placeholder="Your Message"></textarea>
                                            </div>
                                        </div>
            
                                        <div class="col-lg-12 col-md-12">
                                            <button type="submit" class="default-btn page-btn box-btn">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
            
                        <div class="col-lg-6 col-md-6">
                            <div class="contact-img contact-img-2">
                                <img src="assets/images/contact-img2.jpg" alt="contact" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>